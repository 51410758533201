import React from 'react'
import logo from '../../images/logofirst.png'
import './LandingPage.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {

    AOS.init();

    return (
        // <!-------------------------Footer section--------------------------------------->
        <section id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 footer-box" data-aos="fade-up" data-aos-delay="50"
                        data-aos-duration="1000">
                        <img src={logo} className="footer--logo" alt="company" />
                        <p className="paint">Easy Software Pvt ltd aims to provide creative and easy software for companies and other association.</p>
                        <div style={{ display: "flex" }}><b>Read More</b> <div><i className="fa fa-long-arrow-right" aria-hidden="true" style={{ paddingTop: "4px" }}></i></div></div>
                        <div className='display--social'>
                            <div className='social--media--box'>
                                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                            </div>

                            <div className='social--media--box'>
                                <i className="fa fa-twitter-square" aria-hidden="true"></i>
                            </div>

                            <div className='social--media--box'>
                                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                            </div>

                            <div className='social--media--box'>
                                <i className="fa fa-youtube-play" aria-hidden="true"></i>
                            </div>

                        </div>

                    </div>

                    <div className="col-md-2 footer-box" data-aos="fade-up" data-aos-delay="50"
                        data-aos-duration="1000">
                        <p className="fcontact"><b>Company</b></p>
                        <p className="footer--body--text">About Us</p>
                        <p className="footer--body--text">Services</p>
                        <p className="footer--body--text">Case Studies</p>
                        <p className="footer--body--text">Blog</p>
                        <p className="footer--body--text">Contact</p>
                    </div>
                    <div className="col-md-2 footer-box" data-aos="fade-up" data-aos-delay="50"
                        data-aos-duration="1000">
                        <p className="fcontact"><b>Support</b></p>
                        <p className="footer--body--text">Community</p>
                        <p className="footer--body--text">Resources</p>
                        <p className="footer--body--text">Faqs</p>
                        <p className="footer--body--text">Privacy Policy</p>
                        <p className="footer--body--text">Careers</p>
                    </div>
                    <div className="col-md-3 footer-box" data-aos="fade-up" data-aos-delay="50"
                        data-aos-duration="1000">
                        <p className="fcontact"><b>Contact Us</b></p>
                        <p className="footer--body--text"><i className="fa fa-map-marker"></i>Kalimati, Kathmandu</p>
                        <p className="footer--body--text"><i className="fa fa-phone"></i>977-1-5165236, 977-9803344239 </p>
                        <p className="footer--body--text"><i className="fa fa-envelope-o"></i>easysoftwarenepal@gmail.com</p>
                    </div>
                </div>
                <div className="row download--section" data-aos="fade-up" data-aos-delay="50"
                    data-aos-duration="1000">
                    <div className="col-md-6 download--button">
                        <div className="download">
                            <i class="fa fa-apple" aria-hidden="true"></i>
                            Download for iOS
                        </div>
                        <div className="download--android">
                            <i class="fa fa-android" aria-hidden="true"></i>
                            Download for android
                        </div>
                    </div>

                    <div className="col-md-6 download--copyright">
                        <p>Copyright © 2022 EasySoftware. All rights reserved.</p>
                    </div>

                </div>
                {/* <hr>
		<p className="copyright"> &#169; Pravash Ghimire | 2020</p> */}
            </div>
        </section>
    )
}

export default Footer