import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import UserContext from "../contextfolder/UserContext/UserContext";
import UserEdit from "./UserEdit";

const UserTable = ({ searchTerm }) => {

    const context = useContext(UserContext)
    const { setUserFormValue, setUserFormError, uservalue, editModal, editToggleModal, handleEdit, userList, deactivateNotice } = context;

    const closePopup = (e) => {
        e.preventDefault();
        editToggleModal();
        setUserFormValue(uservalue)
        setUserFormError({})
    }


    const changeStatus = (notID, IsActive) => {
        deactivateNotice(notID, IsActive);
    };

    const checkStatus = (IsActive) => {
        if (IsActive === "A") {
            return "Deactivate";
        } else if (IsActive === "I") {
            return "Activate";
        }
    }



    if (userList.length > 0) {
        return (
            <>
                <div className="table-responsive br-3">

                    <table className="table table-bordereds text-nowrap mb-0">
                        <thead>
                            <tr className="tableHead">
                                <td className="tc">S.N.</td>
                                <td className="tl">UserName</td>
                                <td className="tl">FullName</td>
                                <td className="tl">Address</td>
                                <td className="tc" style={{ width: "220px" }}>
                                    {" "}
                                    Action
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {userList.filter((val) => {
                                if (searchTerm === "") {
                                    return val;
                                } else if (
                                    val.fullName.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    )
                                ) {
                                    return val;
                                }
                            }).map((item, i) => (
                                <tr key={i}>
                                    <td className="tc">{i + 1}</td>
                                    <td className="tl">{item.userName}</td>
                                    <td className="tl">{item.fullName}</td>
                                    <td className="tl"> {item.userAddress}</td>

                                    <td className="tc">
                                        <span
                                            className="editspan badge" onClick={() => handleEdit(item)}

                                        >
                                            Edit
                                        </span> |
                                        <span
                                            className="solvespan badge"
                                            style={{ width: "80px" }}
                                            onClick={() => changeStatus(
                                                item.userCode, item.isActive
                                            )}

                                        >
                                            {checkStatus(item.isActive)}
                                        </span>


                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {editModal && (
                    <div className="modals">
                        <div className="overlay"></div>

                        <div className="commission-model-content">
                            <div className="popUpHeader ">
                                <div className='popUpHeaderText '>Edit User</div>
                                <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                            </div>
                            <div className="inside-modal">
                                <UserEdit />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <div className="table-responsive br-3">

                <table className="table table-bordereds text-nowrap mb-0">
                    <thead>
                        <tr className="tableHead">
                            <td className="tc">S.N.</td>
                            <td className="tl">UserName</td>
                            <td className="tl">FullName</td>
                            <td className="tl">Address</td>
                            <td className="tc" style={{ width: "220px" }}>
                                {" "}
                                Action
                            </td>
                        </tr>
                    </thead>
                </table>
                <div className="text-center mt-4">
                    <p>No data found</p>
                </div>
            </div>

        );
    }
};

export default UserTable;
