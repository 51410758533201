import React, { useState, useEffect, useContext } from 'react'
import CryptoJS from "crypto-js";
import SidebarContext from '../../sidebarcontext/SidebarContext';
import UseHttp from '../../hooks/Use-http.jsx'
import CreditManagementContext from './CreditManagementContext';


function CreditManagementState(props) {
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const { url, setMsg } = useContext(SidebarContext)
    const { sendRequest } = UseHttp();
    const [loading, setLoading] = useState(true);

    const [creditFormError, setCreditFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);


    const [image, setImage] = useState("");
    const [isUploaded, setIsUploaded] = useState(false);
    const [typeFile, setTypeFile] = useState("");

    const [status, setStatus] = useState("")


    // For popup
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    //End

    const creditvalue = {
        creditAmount: '',
        remarks: '',
    };
    const [creditFormValue, setCreditFormValue] = useState(creditvalue);

    const fetchdata = () => {
        const dataForm = {


            Flag: "i",
            CoOperativeCode: userinfo.coOperativeCode,
            UserName: userinfo.UserName,
            CreditAmt: creditFormValue.creditAmount,
            Remarks: creditFormValue.remarks,
            Attachment: image !== null ? image.split(',')[1] : "",
        }


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataForm)
        };
        // fetch(`${url}/admin/bplace`, requestOptions)
        fetch(`${url}/CoOperative/CreditManagement`, requestOptions)
            .then((result) => {
                result.json().then((resp) => {

                    if (resp.statuS_CODE === "0") {
                        getCreditList();
                        setMsg({
                            msg: resp.message,
                            type: "alert alert-success",
                        });
                    } else {
                        setMsg({
                            msg: resp.message,
                            type: "alert alert-danger",
                        });
                    }

                })
            })
    }



    const [creditList, setCreditList] = useState([])

    // API to hit Credit list

    useEffect(() => {
        getCreditList();
    }, [status])

    const getCreditList = () => {
        const dataForm = {


            CoOperativeCode: userinfo.coOperativeCode,
            UserName: userinfo.UserName,
            isApprove: status,
            Flag: "s",
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"
        }


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataForm)
        };
        fetch(`${url}/CoOperative/CreditManagement`, requestOptions)
            .then((result) => {
                result.json().then((resp) => {
                  

                    if (resp.statuS_CODE === "0") {
                        const postResult = resp.creditLst ? resp.creditLst : "";
                        setLoading(false);
                        setCreditList(postResult);

                    } else {
                        setCreditList([]);
                    }

                })
            })
    }








    return (
        <CreditManagementContext.Provider value={{ modal, setModal, toggleModal, creditFormValue, setCreditFormValue, creditFormError, setCreditFormError, isSubmit, setIsSubmit, loading, setLoading, creditvalue, image, setImage, isUploaded, setIsUploaded, typeFile, setTypeFile, fetchdata, creditList, status, setStatus }}>{props.children}</CreditManagementContext.Provider>
    )
}

export default CreditManagementState;

