import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import CommissionContext from "../contextfolder/CommissionContext/CommissionContext";
import CommissionEdit from "./CommissionEdit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CommissionTable = ({ searchTerm }) => {

    const context = useContext(CommissionContext)
    const { commissionList, inputFields, setInputFields, setId } = context;








    const catValidation = (datas, cat) => {
        if (datas.cocategoryType === "P") {
            if (cat <= parseFloat(datas.copercentageRate)) {
                return true;

            } else {

                return false;
            }

        }

        else if (datas.cocategoryType === "F") {

            if (cat <= parseFloat(datas.coflatRate)) {
                return true;
            } else {

                return false;
            }
        }
    }




    const handleFormChange = (data) => {
        // setId(data[0].cucategoryId)


        let getId = data[0].cooperatorName;
        let optName = getId;

        let valResult = catValidation(data[0], data[1])

        if (valResult) {


            let indexList = inputFields ? inputFields.findIndex((list) => list.cooperatorName === optName) : -1;


            if (indexList < 0) {
                setInputFields([
                    ...inputFields, {
                        operatorName: optName,
                        categoryType: data[0].cucategoryType,
                        flatRate: data[0].cucategoryType === "F" ? data[1] : 0,
                        percentageRate: data[0].cucategoryType === "P" ? data[1] : 0,

                    }
                ]);


            }
            // else {
            //     if (data[0].cucategoryType === "F") {
            //         inputFields[indexList].flatRate = data[1];
            //     }
            //     else if (data[0].cucategoryType === "P") {
            //         inputFields[indexList].percentageRate = data[1];
            //     }

            // }


        } else {

            let tagId = data[2].target.name;
            let tag = document.getElementsByName(tagId);
            toast("Error Value Exceeded", {
                style: {
                    color: "red",
                    fontSize: "13px",
                },
                className: "",
            });
            tag[0].value = "";
        }
    }







    if (commissionList.length > 0) {
        return (
            <>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                />
                <div className="outer-wrapper">
                    <div
                        className="table-wrapper"
                    >
                        <table className="table table-striped">
                            <thead>
                                <tr className="tableHead">
                                    <td className="tc">S.N.</td>
                                    <td className="tl">Operator</td>
                                    <td className="tl">Category Type</td>
                                    <td className="tc">Cooperative</td>
                                    <td className="tl">Customer</td>
                                </tr>
                            </thead>
                            <tbody>
                                {commissionList.filter((val) => {

                                    if (searchTerm === "") {
                                        return val;
                                    } else if (
                                        val.cooperatorName.toLowerCase().includes(
                                            searchTerm.toLowerCase()
                                        ) || val.cocategoryType.toLowerCase().includes(
                                            searchTerm.toLowerCase()
                                        )
                                    ) {
                                        return val;
                                    }
                                    return "No data Found"
                                }).map((item, i) => (

                                    <tr key={i}>
                                        <td className="tc">{i + 1}</td>
                                        <td className="tl">{item.cooperatorName}</td>
                                        <td className="tl">{item.cocategoryType === "P" ? "Percentage" : "Flat"}</td>
                                        <td className="tc"> {item.cocategoryType && item.cocategoryType === "P" ? item.copercentageRate : item.coflatRate}</td>

                                        <td className="tc">


                                            <input

                                                type="number"
                                                name={item.cooperatorName}
                                                onChange={(e) => handleFormChange([item, e.target.value, e])}
                                                className="form-control form-control-sm"
                                                style={{ width: "70px" }} min="0"

                                                defaultValue={item.isDefault === "Y" ? (item.cucategoryType === "P" ? item.cupercentageRate : item.cuflatRate) : ""}
                                                max={item.cocategoryType === "P" ? item.copercentageRate : item.coflatRate}

                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div className="outer-wrapper">
                <div
                    className="table-wrapper"
                >
                    <table className="table table-striped">
                        <thead>
                            <tr className="tableHead">
                                <td className="tc">S.N.</td>
                                <td className="tl">Operator</td>
                                <td className="tl">Commission Type</td>
                                <td className="tl">Coopeartive</td>

                                <td className="tl">Customer</td>
                            </tr>
                        </thead>
                    </table>
                    <div className="text-center mt-4">
                        <p>No data found</p>
                    </div>
                </div>
            </div>
        );
    }
};

export default CommissionTable;
