import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import ChequeServiceContext from "../contextfolder/ChequeServiceContext/ChequeServiceContext";

const ChequeServiceTable = ({ searchTerm, componentRef }) => {
  const { chequeServiceList } = useContext(ChequeServiceContext);

  let cheQty = chequeServiceList.reduce(function (prev, current) {
    return prev + +current.cheQty;
  }, 0);

  if (chequeServiceList.length > 0) {
    return (
      <>
        <div className="table-responsive br-3">
          <table
            className="table table-bordereds text-nowrap mb-0"
            id="table-to-xls"
            ref={componentRef}
          >
            <thead>
              <tr className="tableHead">
                <td className="tc" style={{ width: "70px" }}>
                  S.N.
                </td>
                <td className="tl" style={{ width: "145px" }}>
                  Username
                </td>
                <td className="tl" style={{ width: "145px" }}>
                  Account Number
                </td>
                <td className="tc" style={{ width: "80px" }}>
                  Quantity
                </td>
                <td className="tc">Type</td>
                <td className="tl" style={{ width: "200px" }}>
                  Remarks
                </td>
              </tr>
            </thead>
            <tbody>
              {chequeServiceList
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.remarks
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.acNum
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.userID.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item, i) => (
                  <tr key={i}>
                    <td className="tc" style={{ width: "70px" }}>
                      {i + 1}
                    </td>
                    <td className="tl">{item.userID}</td>
                    <td className="tl">{item.acNum}</td>
                    <td className="tc">{item.cheQty}</td>
                    <td className="tc">{item.cheType}</td>
                    <td className="tl" style={{ width: "200px" }}>
                      {item.remarks}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td
                  style={{
                    borderRight: "1px solid transparent",
                  }}
                ></td>

                <td
                  style={{
                    borderRight: "1px solid transparent",
                    borderLeft: "1px solid transparent",
                  }}
                ></td>

                <th>Total</th>
                <td className="tc fw-bold">{cheQty}</td>
                <td
                  style={{
                    borderRight: "1px solid transparent",
                  }}
                ></td>
                <td
                  style={{
                    borderLeft: "1px solid transparent",
                  }}
                ></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
    );
  } else {
    return (
      <div className="table-responsive br-3">
        <table className="table table-bordereds text-nowrap mb-0">
          <thead>
            <tr className="tableHead">
              <td className="tc" style={{ width: "70px" }}>
                S.N.
              </td>
              <td className="tl" style={{ width: "145px" }}>
                Username
              </td>
              <td className="tl" style={{ width: "145px" }}>
                Account Number
              </td>
              <td className="tc" style={{ width: "80px" }}>
                Quantity
              </td>
              <td className="tc">Type</td>
              <td className="tl" style={{ width: "200px" }}>
                Remarks
              </td>
            </tr>
          </thead>
        </table>
        <div className="text-center mt-4">
          <p>No Data Found</p>
        </div>
      </div>
    );
  }
};

export default ChequeServiceTable;
