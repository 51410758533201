import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ReversePop from "../ReportPage/ReversePop";
import LoadWalletContext from "../contextfolder/LoadWalletContext/LoadWalletContext";

const LoadWalletTable = ({ searchTerm, componentRef }) => {
  const {
    reportList,
    handleDeleteTrue,
    handleDelete,
    delPopup,
    handleDeleteFalse,
  } = useContext(LoadWalletContext);

  let sum = reportList.reduce(function (prev, current) {
    return prev + +current.amount;
  }, 0);

  if (reportList.length > 0) {
    return (
      <>
        <div className="table-responsive br-3">
          <table
            className="table table-bordereds text-nowrap mb-0"
            id="table-to-xls"
            ref={componentRef}
          >
            <thead>
              <tr className="tableHead">
                <td className="tc">S.N.</td>
                <td className="tc">TranID</td>
                <td className="tc">Date</td>
                <td className="tl">Username</td>
                <td className="tl">Wallet ID</td>
                <td className="tc">Amount</td>
                <td className="tl">Remarks</td>
                <td className="tc">Status</td>
              </tr>
            </thead>
            <tbody>
              {reportList
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.senderAccountNum
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item, i) => (
                  <tr key={i}>
                    <td className="tc">{i + 1}</td>
                    <td className="tc">{item.tranID}</td>
                    <td className="tc">{item.txnDate}</td>
                    <td className="tl">{item.userName}</td>
                    <td className="tl">{item.receiverMobileNum}</td>
                    <td className="tc">{item.amount}</td>
                    <td className="tl">{item.remarks}</td>
                    <td className="tc">
                      {item.status}
                      {item.status === "PENDING" ? (
                        <span>
                          <i
                            className="fas fa-sync ml-3 mr-3 pointer"
                            onClick={() => {
                              handleDelete(item.tranID);
                            }}
                          ></i>
                        </span>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td style={{ borderRight: "1px solid transparent" }}></td>
                <td
                  style={{
                    borderRight: "1px solid transparent",
                    borderLeft: "1px solid transparent",
                  }}
                ></td>
                <td
                  style={{
                    borderRight: "1px solid transparent",
                    borderLeft: "1px solid transparent",
                  }}
                ></td>
                <td
                  style={{
                    borderRight: "1px solid transparent",
                    borderLeft: "1px solid transparent",
                  }}
                ></td>

                <th>Total</th>
                <td className="tc fw-bold">{sum}</td>
                <td
                  style={{
                    borderRight: "1px solid transparent",
                  }}
                ></td>
                <td
                  style={{
                    borderLeft: "1px solid transparent",
                  }}
                ></td>
              </tr>
            </tfoot>
          </table>
        </div>

        {delPopup.show && (
          <ReversePop
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="table-responsive br-3">
        <table className="table table-bordereds text-nowrap mb-0">
          <thead>
            <tr className="tableHead">
              <td className="tc">S.N.</td>
              <td className="tc">TranID</td>
              <td className="tc">Date</td>
              <td className="tl">Username</td>
              <td className="tl">Wallet ID</td>
              <td className="tc">Amount</td>
              <td className="tl">Remarks</td>
              <td className="tc">Status</td>
            </tr>
          </thead>
        </table>
        <div className="text-center mt-4">
          <p>No Data Found</p>
        </div>
      </div>
    );
  }
};

export default LoadWalletTable;
