import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import Spinner from "../../Spinner/loading.gif";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import DeletePop from "./DeletePop";
import CandidateForm from "./CandidateForm";
import ViewCandidate from "./ViewCandidate";
import EliteJobContext from "../../contextfolder/EliteJobContext/EliteJobContext";
import CandidateContext from "../../contextfolder/CandidateContext/CandidateContext";

export default function Candidate() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setCandidateFormValue,
    setCandidateFormError,
    candidateValue,
    candidateList,
    setCandidateList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setCloseChecked,
    setTypeCVFile,
    setCVFile,
    setIsCVUploaded,
    setIsCoverUploaded,
    setTypeCoverFile,
    setCoverFile,
    viewPop,
    toggleViewPopup,
    handleView,
    viewCandidate,
    chooseStatus,
    chooseVia,
    setChooseJob,
    setChooseStatus,
    setChooseVia,
  } = useContext(CandidateContext);
  const { jobList } = useContext(EliteJobContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "FullName",
      minWidth: "170px",
      selector: (row) => row.FullName,
    },

    {
      name: "Email",
      minWidth: "150px",
      selector: (row) => row.Email,
    },
    {
      name: "Contact",
      width: "110px",
      selector: (row) => row.Contact,
    },
    {
      name: "Info",
      center: true,
      width: "110px",
      // selector: (row) => row.CV + "/" + row.Cover,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <a
                className="solvespan--action px-2"
                style={{ color: "#fff", width: "max-content" }}
                href={row.CV}
                target="_blank"
                rel="noreferrer"
              >
                CV
              </a>
              <a
                className="viewspan--action px-2"
                style={{ color: "#fff", width: "max-content" }}
                href={row.Cover}
                target="_blank"
                rel="noreferrer"
              >
                Cover
              </a>
            </div>
          </>
        );
      },
    },
    {
      name: "Created Date",
      width: "112px",
      selector: (row) => row.CreatedDate,
    },
    // {
    //   name: "Status",
    //   width: "100px",
    //   selector: (row) => row.JobStatus,
    // },
    // {
    //   name: "Via",
    //   width: "100px",
    //   selector: (row) => row.Via === "M" && "Manual",
    // },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <i
                className="bi bi-eye  solvespan--action"
                style={{ paddingTop: "3px" }}
                onClick={() => handleView(row)}
              ></i>

              <i
                className="fa fa-trash-o rejectspan--action"
                onClick={() => handleDelete(row)}
              ></i>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setCandidateFormValue(candidateValue);
    setCandidateFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
  };

  const closeViewPopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setCandidateFormValue(candidateValue);
    setCVFile("");
    setIsCVUploaded(false);
    setTypeCVFile(null);

    setCoverFile("");
    setIsCoverUploaded(false);
    setTypeCoverFile(null);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setCandidateList(srchResult);
      } else {
        setCandidateList({});
      }
    } else {
      setCandidateList(originalList);
    }
  };

  const handleSelectJob = (e) => {
    // let name = "designation";
    // setJobFormValue({
    //   ...jobFormValue,
    //   [name]: e.value,
    // });
  };

  const dropDownJob = jobList.map((item) => ({
    value: item.JobID,
    label: item.Designation,
  }));

  return (
    <div className="px-3 pb-3">
      <div className="upper-dataTbl d-flex justify-content-end">
        <div className="btn-addlnote">
          <button
            type="button"
            class="btn btn-sm"
            style={{
              background: "var(--MainColor)",
              color: "white",
            }}
            onClick={addnew}
          >
            Add New
          </button>
        </div>
      </div>
      <hr className="new-hr-new" />
      {loading ? (
        <Spinner />
      ) : (
        <DataTable
          columns={columns}
          data={candidateList}
          customStyles={customStylesForImage}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="280px"
          highlightOnHover
          pointerOnHover
          responsive
          dense
          striped
          subHeader
          subHeaderAlign="left"
          subHeaderComponent={
            <>
              {/* <div
                className="col-lg-2  col-md-2 col-sm-2 dropdown"
                style={{ padding: "inherit", marginBottom: "10px" }}
              >
                <label className="list-label">Job Title</label>

                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  options={dropDownJob}
                  onChange={(item) => setChooseJob(item.value)}
                  isSearchable={true}
                />
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Status</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={chooseStatus}
                  onChange={(e) => setChooseStatus(e.target.value)}
                  className="form-control form-control-sm"
                  aria-label="Default control example "
                >
                  <option
                    value="-1"
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Status
                  </option>
                  <option value="O">Open</option>
                  <option value="C">Close</option>
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Via</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={chooseVia}
                  onChange={(e) => setChooseVia(e.target.value)}
                  className="form-control form-control-sm"
                  aria-label="Default control example "
                >
                  <option
                    value="-1"
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Via
                  </option>
                  <option value="M">Manual</option>
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div> */}

              <div
                className="col-lg-2 offset-lg-10 col-md-2 offset-md-10 col-sm-2 align-self-end Search"
                style={{ padding: "inherit", marginBottom: "10px" }}
              >
                <div className="d-flex">
                  <input
                    placeholder="Search"
                    ref={searchInput}
                    type="text"
                    className="form-control form-control-sm searchField"
                    onChange={searchHandler}
                  />
                </div>
              </div>
            </>
          }
        />
      )}

      {popup && (
        <div
          className="modals"
          style={{
            top: "53%",
            transform: " translate(-50%,-50%)",
            left: "50%",
          }}
        >
          <div
            className="overlay"
            style={{ background: "rgba(0,0,0,0.3)" }}
          ></div>

          <div className="notification-model-content candidate-form">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Candidate</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal candidate-modal">
              <CandidateForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {viewPop && (
        <div
          className="modals"
          style={{
            top: "53%",
            transform: " translate(-50%,-50%)",
            left: "50%",
          }}
        >
          <div
            className="overlay"
            style={{ background: "rgba(0,0,0,0.3)" }}
          ></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">View Candidate</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ViewCandidate
                viewCandidate={viewCandidate}
                closeViewPopup={closeViewPopup}
              />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </div>
  );
}
