import React, { useState, useEffect, useContext } from 'react'
import CryptoJS from "crypto-js";
import SidebarContext from '../../sidebarcontext/SidebarContext';
import UseHttp from '../../hooks/Use-http.jsx'
import NotificationContext from './NotificationContext';
import NepaliDate from 'nepali-date-converter'


function NotificationState(props) {
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { url, setMsg } = useContext(SidebarContext)
    const { sendRequest } = UseHttp();
    const [loading, setLoading] = useState(true);

    const [notificationFormError, setNotificationFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);


    const userinfo = JSON.parse(localStorage.getItem("userInfo"));



    // For popup
    const [modal, setModal] = useState(false);
    const [chooseOption, setChooseOption] = useState("-1")
    const [chooseMember, setChooseMember] = useState("-1")

    const toggleModal = () => {
        setModal(!modal);
    };








    const notificationvalue = {
        name: '',
        description: '',
        memberList: ''
    };
    const [notificationFormValue, setNotificationFormValue] = useState(notificationvalue);


    const [notify, setNotify] = useState(false);

    let date1 = new NepaliDate(new Date())
    const year = date1.getYear()
    const month = date1.getMonth() + 1
    const day = date1.getDate()
    const nepaliDate = year + '/' + month + '/' + day


    //Everything to pop up and delete the slider list
    const [delPopup, setDelPopup] = useState({
        show: false, // initial values set to false and null
        data: null,
    });

    const [Acdata, setACData] = useState()

    // To delete the Account List




    const handleDelete = async (data) => {

        setACData(data)

        setDelPopup({
            show: true,
            data,
        });

    };

    // This will perform the deletion and hide the Confirmation Box


    const handleDeleteTrue = async () => {

        const dataForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345",
            UserName: userinfo.UserName,
            id: Acdata

        }

        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        const dts = {
            EncrytedText: encrypted.toString(),
        };


        const response = await fetch(`${url}/CoOperative/DeleteNotification`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature
            },
            body: JSON.stringify(dts), //dts
        });
        const tole = await response.json();


        if (tole.statuS_CODE === "0") {
            getNotificationList()
            setMsg({
                msg: tole.message,
                type: "alert alert-success",
            });
        } else {
            setMsg({
                msg: tole.message,
                type: "alert alert-danger",
            });
        }
        setDelPopup({
            show: false,
            data: null,

        });
    };

    // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleDeleteFalse = () => {
        setDelPopup({
            show: false,
            data: null,
        });
    };

    // Ends Here





    //API to insert Notification data
    const fetchdata = async () => {

        const dataForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            UserName: notificationFormValue.memberList,
            NotificationHeader: notificationFormValue.name,
            NotificationBody: notificationFormValue.description,
            NepaliDate: nepaliDate,
            EnglishDate: new Date(),
            CreatedMemId: userinfo.UserID,
            NotificationType: notify ? "Y" : "N",
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"
        }
        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const dts = {
            EncrytedText: encrypted.toString(),
        };
        const response = await fetch(`${url}/CoOperative/SentNotification`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const pay = await response.json();


        if (pay.statuS_CODE === "0") {
            getNotificationList();
            setMsg({
                msg: pay.message,
                type: "alert alert-success",
            });
        } else if (pay.statuS_CODE === "1") {
            setMsg({
                msg: pay.message,
                type: "alert alert-danger",
            });
        }

    };

    const [notificationList, setNotificationList] = useState([])

    //API to hit Notification list

    useEffect(() => {
        getNotificationList();
    }, [chooseMember])

    const getNotificationList = () => {
        const listForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            // UserName: userinfo.UserName,
            UserName: chooseMember,
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345",
            flag: "n"
        };
        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(listForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        const dts = {
            EncrytedText: encrypted.toString(),
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts)
        };
        fetch(`${url}/CoOperative/GetNotification`, requestOptions)
            .then((result) => {
                result.json().then((resp) => {

                    if (resp.statuS_CODE === "0") {
                        setNotificationList(resp.lstNotification)
                        setLoading(false)
                    }
                    else if (resp.statuS_CODE === "1") {
                        setNotificationList({})
                        setLoading(false)

                    }
                })
            })
    };



    return (
        <NotificationContext.Provider value={{ modal, setModal, toggleModal, notificationFormError, setNotificationFormError, isSubmit, setIsSubmit, notificationvalue, notificationFormValue, setNotificationFormValue, chooseOption, setChooseOption, notify, setNotify, fetchdata, notificationList, handleDeleteTrue, handleDeleteFalse, delPopup, handleDelete, chooseMember, setChooseMember }}>{props.children}</NotificationContext.Provider>
    )
}

export default NotificationState;

