import React, { useContext, useState } from "react";
import "../../css/Commission.css";
import UserContext from "../contextfolder/UserContext/UserContext";
import UserForm from "./UserForm";
import UserTable from "./UserTable";

function User() {
  const context = useContext(UserContext);
  const { modal, toggleModal, setUserFormValue, setUserFormError, uservalue } =
    context;

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setUserFormValue(uservalue);
    setUserFormError({});
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setUserFormValue(uservalue);
  };

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="header--section">
          <div className="fontHeader">User</div>

          <div className="header--button">
            <button className="btn btn-cmpy btn-sm" onClick={addnew}>
              {" "}
              Add User +
            </button>
          </div>
        </div>
        <hr />

        <section className="content-section main-content">
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-lg-2 col-md-6 col-sm-6 p-2 Search">
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <UserTable searchTerm={searchTerm} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>
      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="commission-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add User</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <UserForm />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default User;
