import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";

const OperatorTable = ({ searchTerm, componentRef }) => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { url, setMsg } = useContext(SidebarContext);
  const [loading, setLoading] = useState(true);

  const [summaryList, setSummaryList] = useState([]);

  useEffect(() => {
    OperatorSummary();
  }, []);

  let noOfTxns = summaryList.reduce(function (prev, current) {
    return prev + +current.noOfTxns;
  }, 0);
  let sumOfAmount = summaryList.reduce(function (prev, current) {
    return prev + +current.sumOfAmount;
  }, 0);
  let coCommission = summaryList.reduce(function (prev, current) {
    return prev + +current.coCommission;
  }, 0);

  const OperatorSummary = () => {
    const listForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(listForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts),
    };
    fetch(`${url}/Reports/OperatorSummary`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.statuS_CODE === "0") {
          setSummaryList(resp.lstOperatorSummaryReport);
          setLoading(false);
        } else {
          setSummaryList([]);
          setLoading(false);
        }
      });
    });
  };

  if (summaryList.length > 0) {
    return (
      <>
        <div className="table-responsive br-3">
          <table
            className="table table-bordereds text-nowrap mb-0"
            id="table-to-xls"
            ref={componentRef}
          >
            <thead>
              <tr className="tableHead">
                <td className="tc">S.N.</td>
                <td className="tl">Operator</td>
                <td className="tc">Transaction</td>
                <td className="tc">Amount</td>
                <td className="tc">Comm. Earned</td>
              </tr>
            </thead>
            <tbody>
              {summaryList
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.operatorName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item, i) => (
                  <tr key={i}>
                    <td className="tc">{i + 1}</td>
                    <td className="tl">{item.operatorName}</td>
                    <td className="tc">{item.noOfTxns}</td>
                    <td className="tc">{item.sumOfAmount}</td>
                    <td className="tc">{item.coCommission.toFixed(2)}</td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <th>Total</th>
                <td className="tc fw-bold">{noOfTxns}</td>
                <td className="tc fw-bold">{sumOfAmount}</td>
                <td className="tc fw-bold">{coCommission}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
    );
  } else {
    return (
      <div className="table-responsive br-3">
        <table className="table table-bordereds text-nowrap mb-0">
          <thead>
            <tr className="tableHead">
              <td className="tl">S.N.</td>
              <td className="tl">Operator</td>
              <td className="tl">Transaction</td>
              <td className="tl">Amount</td>
              <td className="tl">Comm. Earned</td>
            </tr>
          </thead>
        </table>
        <div className="text-center mt-4">
          <p>No Data Found</p>
        </div>
      </div>
    );
  }
};

export default OperatorTable;
