import React, { useContext } from "react";
import "../../css/Commission.css";
import CooperativeContext from "../contextfolder/Cooperativecontext/CooperativeContext";
import CooperativeEdit from "./CooperativeEdit";

const CooperativeTable = ({ searchTerm }) => {
    const context = useContext(CooperativeContext)

    const { editModal, toggleEditModal, setCooperativeFormError, cooperativevalue, setCooperativeFormValue } = context;

    const closePopup = (e) => {
        e.preventDefault();
        toggleEditModal();
        setCooperativeFormValue(cooperativevalue)
        setCooperativeFormError({})
    }

    //   if (eventList.length > 0) {
    return (
        <>
            <div className="outer-wrapper">
                <div
                    className="table-wrapper"
                    style={{
                        overflowX: "scroll",
                        overflowY: "scroll",
                        margin: "3px",
                        height: "340px",
                    }}
                >
                    <table className="table table-striped">
                        <thead>
                            <tr className="tableHead">
                                <td className="tc">S.N.</td>
                                <td className="tc">Logo</td>
                                <td className="tc">Coop Code</td>
                                <td className="tl">Coop Name</td>
                                <td className="tc">Contact</td>
                                <td className="tc">Credit Limit</td>
                                <td className="tc">No. of User</td>
                                <td className="tc">Expiry Date</td>
                                <td className="tc">Allow App</td>
                                <td className="tc" style={{ width: "220px" }}>
                                    {" "}
                                    Action
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {eventList.filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.EventTitle.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      ) 
                    ) {
                      return val;
                    }
                  }).map((item, i) => ( */}
                            {/* <tr key={i}> */}
                            <tr>
                                <td className="tc">S.N.</td>
                                <td className="tc">Logo</td>
                                <td className="tc">Coop Code</td>
                                <td className="tl">Coop Name</td>
                                <td className="tc">Contact</td>
                                <td className="tc">Credit Limit</td>
                                <td className="tc">No. of User</td>
                                <td className="tc">Expiry Date</td>
                                <td className="tc">Allow App</td>
                                <td className="tc">
                                    <span
                                        className="rejectspan badge" onClick={toggleEditModal}

                                    >
                                        Edit
                                    </span> |
                                    <span
                                        className="editspan badge"


                                    >
                                        Allow/Disallow
                                    </span>


                                </td>
                            </tr>
                            {/* ))} */}
                        </tbody>
                    </table>
                </div>
            </div>
            {editModal && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="modal-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Edit Cooperative</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-cooperative">
                            <CooperativeEdit />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
    //   } else {
    //     return (
    //       <div className="outer-wrapper">
    //         <div
    //           className="table-wrapper"
    //           style={{
    //             overflowX: "scroll",
    //             overflowY: "scroll",
    //             margin: "3px",
    //             height: "400px",
    //           }}
    //         >
    //           <table className="table table-striped">
    //             <thead>
    //               <tr className="tableHead">
    //    <td className="tc">S.N.</td>
    // <td className="tc">Comp ID</td>
    // <td className="tc">Logo</td>
    // <td className="tl">Comp Name</td>
    // <td className="tc">License Expiry</td>
    // <td className="tc">Credit Limit</td>
    // <td className="tc">No. of User</td>
    //     <td className="tc" style={{ width: "220px" }}>
    //         {" "}
    //         Action
    //     </td>
    // </tr>
    //             </thead>
    //           </table>
    //           <div className="text-center mt-4">
    //             {/* <Spinner /> */}
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   }
};

export default CooperativeTable;
