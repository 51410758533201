import React, { useContext, useState, useEffect } from 'react'
import CooperativeContext from '../contextfolder/Cooperativecontext/CooperativeContext';
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";

export default function CooperativeEdit(props) {

    const context = useContext(CooperativeContext)

    const { toggleEditModal, cooperativeFormError, setCooperativeFormError, cooperativevalue, cooperativeFormValue, setCooperativeFormValue, typeFile, setTypeFile, image, setImage, isUploaded, setIsUploaded, editIsSubmit, setEditIsSubmit } = context;



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setCooperativeFormValue({ ...cooperativeFormValue, [name]: value });
    };

    const formSubmit = (e) => {

        e.preventDefault();
        setCooperativeFormError(validate(cooperativeFormValue));
        setEditIsSubmit(true);
    }
    // useEffect(() => {
    //     if (Object.keys(commissionFormError).length === 0 && editIsSubmit) {
    //         fetchdata(commissionFormError)
    //         setPopup(false)
    //         setProfessionFormValue(professionvalue)
    //         setProfessionSubmit(false);
    //     }

    // }, [commissionFormError]);



    const closePopup = (e) => {
        e.preventDefault();
        toggleEditModal();
        setCooperativeFormValue(cooperativevalue)
        setCooperativeFormError({})
    }

    const validate = (values) => {
        const errors = {};
        const numv = /^[0-9]+$/i;
        const digits = /^\d{10}$/;
        if (!values.CoopID) {
            errors.CoopID = "Required";
        }
        if (!values.nickName) {
            errors.nickName = "Required";
        }
        if (!values.address) {
            errors.address = "Required";
        }
        if (!values.contactNumber) {
            setEditIsSubmit(true);
        } else if (!numv.test(values.contactNumber)) {
            errors.contactNumber = "Must be digits";
        } else if (!digits.test(values.contactNumber)) {
            errors.contactNumber = "Must be 10 digits";
        }
        if (!values.logo) {
            errors.logo = "Required";
        }
        if (!values.serverName) {
            errors.serverName = "Required";
        }
        if (!values.dbName) {
            errors.dbName = "Required";
        }

        return errors;
    };

    // function handleImageChange(e) {
    //     if (e.target.files && e.target.files[0]) {
    //         setTypeFile(e.target.files[0].type);
    //         let reader = new FileReader();

    //         reader.onload = function (e) {
    //             setImage(e.target.result);
    //             setIsUploaded(true);
    //         };

    //         reader.readAsDataURL(e.target.files[0]);
    //     }
    // }


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-row">
                        <div className="form-group col-md-4  ">
                            <label className="form-label" htmlFor="CoopID">Coop. ID<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.CoopID} id="CoopID" name="CoopID" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{cooperativeFormError.CoopID}</p>
                        </div>


                        <div className="form-group col-md-8">

                            <label className="form-label" htmlFor="CoopName">Cooperative Name</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.CoopName} id="CoopName" name="CoopName" />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6  ">
                            <label className="form-label" htmlFor="nickName">Nick Name<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.nickName} id="nickName" name="nickName" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{cooperativeFormError.nickName}</p>
                        </div>


                        <div className="form-group col-md-6">

                            <label className="form-label" htmlFor="address">Address<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.address} id="address" name="address" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{cooperativeFormError.address}</p>
                        </div>
                    </div>


                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="contactPerson">Contact Person<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.contactPerson} id="contactPerson" name="contactPerson" />
                        </div>


                        <div className="form-group col-md-6">

                            <label className="form-label" htmlFor="contactNumber">Contact Number</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.contactNumber} id="contactNumber" name="contactNumber" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{cooperativeFormError.contactNumber}</p>
                        </div>
                    </div>



                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="form-label" htmlFor="url">CBS URL<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.url} id="url" name="url" />
                        </div>
                    </div>


                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label className="form-label" htmlFor="creditLimit">Credit Limit</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.creditLimit} id="creditLimit" name="creditLimit" />
                        </div>


                        <div className="form-group col-md-4">

                            <label className="form-label" htmlFor="noOfUser">No. of User</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.noOfUser} id="noOfUser" name="noOfUser" />
                        </div>

                        <div className="form-group col-md-4">

                            <label className="form-label" htmlFor="expire">License Expire</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.expire} id="expire" name="expire" />
                        </div>
                    </div>



                    <div className="form-row">
                        <div className="form-group col-md-4  ">
                            <label className="form-label" htmlFor="color">Color</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.color} id="color" name="color" />
                            {/* <p className="errormsg ">{cooperativeFormError.color}</p> */}
                        </div>

                        <div className="form-group col-md-8">

                            <label className="form-label" htmlFor="logo">Logo<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.logo} id="logo" name="logo" />
                            <p className="errormsg ">{cooperativeFormError.logo}</p>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6  ">
                            <label className="form-label" htmlFor="serverName">Server Name<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.serverName} id="serverName" name="serverName" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{cooperativeFormError.serverName}</p>
                        </div>


                        <div className="form-group col-md-6">

                            <label className="form-label" htmlFor="dbName">DB Name<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.dbName} id="dbName" name="dbName" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{cooperativeFormError.dbName}</p>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="dbUsername">DB Username</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.dbUsername} id="dbUsername" name="dbUsername" />
                            {/* <p className="errormsg ">{cooperativeFormError.color}</p> */}
                        </div>


                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="dbPassword">DB Password</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.dbPassword} id="dbPassword" name="dbPassword" />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="serverKey">Server Key</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.serverKey} id="serverKey" name="serverKey" />
                            {/* <p className="errormsg ">{cooperativeFormError.color}</p> */}
                        </div>


                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="senderId">Sender ID</label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={cooperativeFormValue.senderId} id="senderId" name="senderId" />
                        </div>
                    </div>

                    {/* <div className="form-group  ">
                        <div className="form-label" htmlFor="text">
                            Upload Image
                        </div>
                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!isUploaded ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div> */}


                </form>

            </div>
            <div className=" stickyFormFooter">
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Update</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div>

        </>

    )
}
