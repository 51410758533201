import React, { useEffect, useState } from "react";
import SidebarContext from "./SidebarContext";
import { defaultThemes } from "react-data-table-component";
function SidebarState(props) {
  const [menutoggle, setMenutoggle] = useState(false);
  const [logoutDropdownactive, setlogoutDropdownactive] = useState(false);
  const [alert, setAlert] = useState({
    fade: "fade-default",
    msg: "",
    type: "",
  });
  const [logoutdata, setLogout] = useState(false);
  const [resetPassword, setresetPassword] = useState(false);
  const [msg, setMsg] = useState({});

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    cells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    rows: {
      style: {
        height: "17px",
      },
      highlightOnHoverStyle: {
        backgroundColor: "#f2f0f0",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        fontWeight: "650",
      },
    },
  };

  const customStylesForImage = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    cells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f2f0f0",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        fontWeight: "650",
      },
    },
  };

  const url = process.env.REACT_APP_URL;
  const jobURL = process.env.REACT_APP_JOB;

  return (
    <SidebarContext.Provider
      value={{
        menutoggle,
        setMenutoggle,
        logoutDropdownactive,
        setlogoutDropdownactive,
        alert,
        setAlert,
        logoutdata,
        setLogout,
        resetPassword,
        setresetPassword,
        msg,
        setMsg,
        url,
        jobURL,
        customStylesForImage,
        customStyles,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
}

export default SidebarState;
