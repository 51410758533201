import React, { useContext, useState, useRef } from 'react'
import '../../css/Commission.css';
import ChequeServiceContext from '../contextfolder/ChequeServiceContext/ChequeServiceContext';
import ReportContext from '../contextfolder/ReportContext/ReportContext';
import ChequeServiceTable from './ChequeServiceTable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useReactToPrint } from "react-to-print";



function ChequeService() {

    const { chooseMember, setChooseMember, loading, chequeType, setChequeType } = useContext(ChequeServiceContext)


    const handleMember = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setChooseMember(value);

    };

    const handleType = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setChequeType(value);

    };

    const { depoUserList } = useContext(ReportContext)


    const [searchTerm, setSearchTerm] = useState("");



    // For print purpose

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'report-data',
        // onAfterPrint: () => alert("print success")
    });

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
                <div>
                    <div className="mainHeader">
                        <div className="fontHeader">Cheque Service</div>

                    </div>
                    <hr />
                </div>
                <section className="content-section main-content">
                    <div className="content">
                        <div className=" col-lg-12 col-sm-12">

                            <div className="row">


                                <div className="col-lg-2  col-md-3 col-sm-2">
                                    <label>Member</label>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="snotifiaction"
                                        value={chooseMember}
                                        onChange={handleMember}
                                        className="form-control form-control-sm mb-1"
                                    >
                                        <option selected disabled value="0" style={{ fontSize: "11px" }}>
                                            Choose Member
                                        </option>
                                        <option value="">All</option>
                                        {depoUserList.map((item) => (<option key={item.memID} value={item.userName}>{item.fullName}</option>))}
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>

                                <div className="col-lg-2 col-md-3 col-sm-2">
                                    <label>Status</label>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="status"
                                        className="form-control form-control-sm mb-1"
                                        value={chequeType}
                                        onChange={handleType}
                                    >
                                        <option selected disabled value="0" style={{ fontSize: "11px" }}>
                                            Select Status
                                        </option>
                                        <option value="">All</option>
                                        <option value="CHEQUERESUME">Cheque Resume</option>
                                        <option value="CHEQUEBLOCK">Cheque Block</option>
                                        <option value="CHEQUEREQUEST">Cheque Request</option>
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-2 print-pdf-cheque" >

                                    <i className="fa fa-print account-bar" aria-hidden="true" onClick={handlePrint} style={{ fontSize: "18px" }}></i>

                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="fa fa-file-excel-o"
                                        table="table-to-xls"
                                        filename="report"
                                        sheet="tablexls"
                                        buttonText="" >
                                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                                    </ReactHTMLTableToExcel>


                                </div>
                                <div className="col-lg-2 offset-lg-4 col-md-3 col-sm-2 search-icon-position-cheque" style={{ padding: "5px" }}>


                                    <input type="text" placeholder="Search" value={searchTerm} className="form-control form-control-sm mb-1" style={{ fontSize: "12px" }}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }} />
                                    <i style={{ color: "#999", top: "14px" }} className="fas fa-search searchIconPosition"></i>
                                </div>
                            </div>
                        </div>
                        {<div className="row">
                            <div className="">
                                <div className="col-lg-12 p-1">

                                    <ChequeServiceTable searchTerm={searchTerm} componentRef={componentRef} />

                                </div>
                            </div>

                        </div>}


                    </div>
                </section>
            </div>

        </>
    )
}

export default ChequeService