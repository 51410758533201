import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../contextfolder/UserContext/UserContext';

export default function UserForm(props) {

    const context = useContext(UserContext)

    const { toggleModal, userFormError, setUserFormError, isSubmit, setIsSubmit, uservalue, userFormValue, setUserFormValue, fetchdata } = context;



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setUserFormValue({ ...userFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setUserFormError(validate(userFormValue));
        setIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(userFormError).length === 0 && isSubmit) {
            fetchdata(userFormValue)
            setUserFormValue(uservalue)
            setIsSubmit(false);
            toggleModal();
        }

    }, [userFormError]);



    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setUserFormValue(uservalue)
        setUserFormError({})
        setIsSubmit(false);
    }

    const validate = (values) => {
        const errors = {};
        if (!values.userName) {
            errors.userName = "Required";
        }
        if (!values.fullName) {
            errors.fullName = "Required";
        }
        if (!values.address) {
            errors.address = "Required";
        }

        return errors;
    };




    return (
        <>

            <div className="container-fluid basicform" >

                <form >
                    <div className="form-group">
                        <label className="form-label" htmlFor="userName">User Name<sup className="sup-col">*</sup></label>
                        <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={userFormValue.userName} id="userName" name="userName" />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{userFormError.userName}</p>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="fullName">Full Name<sup className="sup-col">*</sup></label>
                        <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={userFormValue.fullName} id="fullName" name="fullName" />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{userFormError.fullName}</p>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="address">Address<sup className="sup-col">*</sup></label>
                        <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={userFormValue.address} id="address" name="address" />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{userFormError.address}</p>
                    </div>


                </form>

            </div>
            <div className=" FormFooter">
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div>

        </>

    )
}
