import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import CreditManagementContext from "../contextfolder/CreditManagementContext/CreditManagementContext";
import { showImgPreview } from "../hooks/ImagePreview";


const CreditManagementTable = ({ searchTerm, componentRef }) => {


    const { creditList } = useContext(CreditManagementContext)
    const [imgPrv, setImgPrv] = useState(false);
    const [imagePre, setImagePre] = useState("");




    if (creditList.length > 0) {
        return (
            <>
                <div className="table-responsive br-3">

                    <table className="table table-bordereds text-nowrap mb-0" id="table-to-xls" ref={componentRef}>
                        <thead>
                            <tr className="tableHead ">
                                <td >S.N.</td>
                                <td className="tl">Username</td>
                                <td className="tr">Credit Amount</td>
                                <td className="tl">Remarks</td>
                                <td className="tl">Status</td>
                                <td className="tl">Created Date</td>
                                <td className="tl">Approved Date</td>
                                <td >Attachment</td>
                            </tr>
                        </thead>
                        <tbody>
                            {creditList.filter((val) => {
                                if (searchTerm === "") {
                                    return val;
                                } else if (
                                    val.remarks.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    ) || val.userName.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    )

                                ) {
                                    return val;
                                }
                            }).map((item, i) => (
                                <tr key={i}>
                                    <td className="tc">{i + 1}</td>
                                    <td>{item.userName}</td>
                                    <td className="tr">{item.creditAmt.toFixed(2)}</td>
                                    <td className="tl">{item.remarks}</td>
                                    <td className="tl">{item.isApprove}</td>
                                    <td className="tl">{item.createdDte}</td>
                                    <td className="tl">{item.approvedDate === null ? "PENDING" : item.approvedDate}</td>
                                    <td style={{ display: "flex", justifyContent: "center" }} ><span className="imagespan" src={item.attachment} onClick={() => { setImagePre(item.attachment); setImgPrv(true) }} > <p>View</p> </span> </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                {imgPrv &&
                    showImgPreview({
                        img: imagePre,
                        setTrigger: setImgPrv,
                    })}

            </>
        );
    }
    else {
        return (
            <div className="table-responsive br-3">

                <table className="table table-bordereds text-nowrap mb-0">
                    <thead>
                        <tr className="tableHead">

                            <td className="tc">S.N.</td>
                            <td className="tl">Username</td>
                            <td className="tc">Credit Amount</td>
                            <td className="tl">Remarks</td>
                            <td className="tc">Status</td>
                            <td className="tl">Attachment</td>


                        </tr>
                    </thead>
                </table>
                <div className="text-center mt-4">
                    <p>No Data Found</p>
                </div>
            </div>
        );
    }
};

export default CreditManagementTable;
