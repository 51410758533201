import React, {
  useState,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "../css/Uppersidebar.css";
import SidebarContext from "./sidebarcontext/SidebarContext";
import AuthContext from "./auth-context";
import OutsideAlerterlogout from "./hooks/OutsideAlerterLogout";
import logo from '../images/logo.png'

export default function Uppersidebar() {
  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const context = useContext(SidebarContext);
  const {
    menutoggle,
    setMenutoggle,
    logoutDropdownactive,
    setlogoutDropdownactive,
    alert,
    logoutdata,
    setLogout,
    setAlert,
    chhimekiList
  } = context;

  const [searchpop, setsearchPop] = useState(false);
  // 

  const handleLogoClick = (e) => {
    e.preventDefault();
    setlogoutDropdownactive(!logoutDropdownactive);
  };
  function logOut(e) {
    e.preventDefault();
    setlogoutDropdownactive(!logoutDropdownactive);
    setAlert({
      fade: "fade-in",
      msg: "Do you want to Logout ?",
      type: "logout",
    });
  }
  function logoutFunction() {
    authCtx.logout();
    sessionStorage.clear();
    localStorage.clear();
    setAlert({
      fade: "fade-default",
    });
    navigate("/login");
  }

  useEffect(() => {
    if (logoutdata) {
      logoutFunction();
    }
  }, [logoutdata]);

  const searchPopup = (e) => {
    e.preventDefault();
    setsearchPop(!searchpop);
  };
  const closeSearchPopup = (e) => {
    e.preventDefault();
    setsearchPop(false);
  };
  useEffect(() => {
    if (searchpop) {
      $(".displaySearchbar").slideDown(100);
    } else {
      $(".displaySearchbar").slideUp(100);
    }
  }, [searchpop]);


  const handleMenuChange = () => {
    setMenutoggle(!menutoggle);

  };

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const coOperativeName = userinfo.coOperativeName;
  const coOperativeAddress = userinfo.coOperativeAddress;
  const coOperativeCode = userinfo.coOperativeCode;
  const coOperativeImg = userinfo.coOperativeImg;


  const refresh = () => {
    window.location.reload();
  }


  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 UpperNav">
        <div className="wrapper">
          <div className="navbar">
            <div className="navbar__left">
              <button className="toogle" onClick={handleMenuChange}>
                <i className="fas fa-bars"></i>
              </button>
              <span className="mx-3"></span>

              <div className="mainNavUserInfo pl-0" >
                <div className="userLogo">
                  <div className="userImg pl-0">
                    <img
                      src={coOperativeImg}
                      alt=""
                    />
                  </div>

                </div>
                <div className="uppernavuserInfotext pl-2">
                  {" "}
                  <p className="mb-0">{coOperativeName}({coOperativeCode})</p>
                  <p className="mb-0">{coOperativeAddress}</p>
                </div>
              </div>

            </div>


            <div className="navbar__right">
              <div className="d-flex justify-content-center align-items-center ">
                {/* <i className="fas fa-search pointer" onClick={searchPopup}></i> */}
                <i className="fas fa-sync ml-4 mr-3 pointer" onClick={refresh}></i>
                {/* <i className="fas fa-comment-dots ml-4 pointer"></i> */}
                {/* <i className="fas fa-bell mx-4 pointer"></i> */}
                <i
                  className="fas fa-power-off mr-2 pointer"
                  onClick={handleLogoClick}
                ></i>
              </div>

              {logoutDropdownactive && (
                <OutsideAlerterlogout>
                  <div className="dropLogomenu">
                    <ul>
                      <li>
                        <a href="" onClick={logOut}>
                          <span>
                            <i className="fas fa-power-off mr-2"></i>Logout
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </OutsideAlerterlogout>
              )}
            </div>

            {/* <div className="searchbar displaySearchbar">
              <div className="searchBarPosition">
                <input
                  type="text"
                  className="form-control seachradius"
                  placeholder="Search...."
                />
                <div className="searchbarabsolute">
                  <div className="cooperative-search-field">
                    <select
                      className="form-control"
                      style={{ width: "150px", fontSize: "12px" }}
                    >
                      <option >Select an Option</option>
                      <option value="Cooperative">Cooperative</option>
                      <option value="Collector">Collector</option>
                    </select>
                    <i
                      style={{ paddingTop: "4px", color: "#999" }}
                      className="fas fa-angle-down notificationpositondrop"
                    ></i>
                  </div>
                </div>
                <div className="searchcloseAbsolute">
                  <i
                    className="fas fa-times  pointer searchBarclose"
                    onClick={closeSearchPopup}
                  ></i>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </>
  );
}
