import React, { useContext, useState, useRef } from "react";
import "../../css/Commission.css";
import "../../css/Notification.css";
import ReportContext from "../contextfolder/ReportContext/ReportContext";
import ReportTable from "./ReportTable";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";

function Report() {
  const {
    chooseMember,
    setChooseMember,
    reportFormValue,
    setReportFormValue,
    reportvalue,
    depoUserList,
    generatePDF,
    chooseOperator,
    setChooseOperator,
    operatorList,
  } = useContext(ReportContext);
  // const handleChange = (e) => {
  //     const target = e.target;
  //     const value = target.value;
  //     setChooseOption(value);

  // };

  const handleChanges = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseMember(value);
  };

  const handleOperator = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseOperator(value);
  };

  const handleReport = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setReportFormValue({ ...reportFormValue, [name]: value });
  };
  const handleDate = ({ adDate }) => {
    setReportFormValue({ ...reportFormValue, fromDate: adDate });
  };
  const handleDate1 = ({ adDate }) => {
    setReportFormValue({ ...reportFormValue, toDate: adDate });
  };

  const [searchTerm, setSearchTerm] = useState("");

  // For print purpose

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "report-data",
    // onAfterPrint: () => alert("print success")
  });

  return (
    <>
      <div className=" col-lg-12 col-sm-12">
        <div className="row">
          <div className="col-lg-2  col-md-3 col-sm-2">
            <label>Member</label>
            <select
              style={{ fontSize: "11px" }}
              name="snotifiaction"
              className="form-control form-control-sm mb-1"
              value={chooseMember}
              onChange={handleChanges}
            >
              <option value="0" disabled selected style={{ fontSize: "11px" }}>
                Select Option
              </option>
              <option value="">All</option>
              {depoUserList.map((item) => (
                <option key={item.memID} value={item.userName}>
                  {item.fullName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>
          {/* {chooseOption === "member" && (

                        <div className="col-lg-2 col-md-3 col-sm-2 report-dropdown">
                            <select
                                style={{ fontSize: "11px" }}
                                name="snotifiaction"
                                value={chooseMember}
                                onChange={handleChanges}
                                className="form-control form-control-sm mb-1"
                            >
                                <option selected style={{ fontSize: "11px" }}>
                                    Select Option
                                </option>
                                
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                        </div>
                    )} */}

          <div className="col-lg-2 col-md-3 col-sm-2 ">
            <label>From Date</label>
            {/* <input type="date"
                            id="fromDate"
                            name="fromDate"
                            className="form-control form-control-sm mb-1"
                            value={reportFormValue.fromDate}
                            onChange={handleReport}
                        /> */}
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={reportFormValue.fromDate}
              onChange={handleDate}
            />
          </div>

          <div className="col-lg-2 col-md-3 col-sm-2 ">
            <label>To Date</label>
            {/* <input type="date"
                            name="toDate"
                            className="form-control form-control-sm mb-1"
                            value={reportFormValue.toDate}
                            onChange={handleReport}

                        /> */}
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={reportFormValue.toDate}
              onChange={handleDate1}
            />
          </div>

          <div className="col-lg-2 col-md-3 col-sm-2">
            <label>Status</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm mb-1"
              value={reportFormValue.status}
              onChange={handleReport}
            >
              <option selected disabled value="0" style={{ fontSize: "11px" }}>
                Select Status
              </option>
              <option value="">All</option>
              <option value="PROCESSING">Processing</option>
              <option value="SUCCESS">Success</option>
              <option value="FAIL">Failed</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-2">
            <label>Operator</label>
            <select
              style={{ fontSize: "11px" }}
              name="operator"
              className="form-control form-control-sm mb-1"
              value={chooseOperator}
              onChange={handleOperator}
            >
              <option selected disabled value="0" style={{ fontSize: "11px" }}>
                Select Operator
              </option>
              <option value="">All</option>
              {operatorList.map((item) => (
                <option key={item.operatorValue} value={item.operatorValue}>
                  {item.operatorName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div
            className="col-lg-2 col-md-3 col-sm-2 search-icon-position"
            style={{ padding: "5px" }}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <i
              style={{ color: "#999", top: "14px" }}
              className="fas fa-search searchIconPosition"
            ></i>
          </div>
        </div>
      </div>
      <div className="col-lg-1 offset-lg-11 col-md-1 offset-md-11 col-sm-1 print-pdf-report">
        <i
          className="fa fa-print account-bar"
          aria-hidden="true"
          onClick={handlePrint}
          style={{ fontSize: "18px" }}
        ></i>

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="fa fa-file-excel-o"
          table="table-to-xls"
          filename="report"
          sheet="tablexls"
          buttonText=""
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </ReactHTMLTableToExcel>
      </div>
      {
        <div className="row">
          <div className="">
            <div className="col-lg-12 p-1">
              <ReportTable
                searchTerm={searchTerm}
                reportFormValue={reportFormValue}
                componentRef={componentRef}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Report;
