import React, { useContext, useEffect, useRef, useState } from "react";
import "../../css/Commission.css";
import ReportContext from "../contextfolder/ReportContext/ReportContext";
import Spinner from "../Spinner/Spinner";
import ReversePop from "./ReversePop";
import $ from "jquery";

const ReportTable = ({ searchTerm, reportFormValue, componentRef }) => {
  const {
    reportList,
    loading,
    handleDelete,
    delPopup,
    handleDeleteFalse,
    handleDeleteTrue,
  } = useContext(ReportContext);

  let amount = reportList.reduce(function (prev, current) {
    return prev + +current.amount;
  }, 0);

  let balance = reportList.reduce(function (prev, current) {
    return prev + +current.balance;
  }, 0);
  
  let coCommission = reportList.reduce(function (prev, current) {
    return (
      prev +
      (current.coCommission === null ||
      current.tranStatus === "PROCESSING" ||
      current.tranStatus === "FAIL"
        ? 0
        : +current.coCommission)
    );
  }, 0);

  if (reportList.length > 0) {
    return (
      <>
        <div className="table-responsive br-3">
          {loading ? (
            <Spinner />
          ) : (
            <table
              className="table table-bordereds text-nowrap mb-0"
              id="table-to-xls"
              ref={componentRef}
            >
              <thead>
                <tr className="tableHead">
                  <td className="tc">S.N.</td>
                  <td className="tc">Trans. ID</td>
                  <td className="tl">Member</td>
                  <td className="tl">Mobile Number</td>
                  <td className="tc">Date</td>
                  <td className="tc">Operator</td>
                  <td className="tc">Status</td>
                  <td className="tc">Amount</td>
                  <td className="tc">Balance</td>
                  <td className="tc">Comm. Earned</td>
                  {/* <td className="tc" style={{ width: "150px" }}>
                                            {" "}
                                            Action
                                        </td> */}
                </tr>
              </thead>
              <tbody>
                {reportList
                  .filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.userName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      val.operator
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((item, i) => (
                    <tr key={i}>
                      <td className="tc">{i + 1}</td>
                      <td className="tc">{item.tranID}</td>
                      <td className="tl">{item.userName}</td>
                      <td className="tl">{item.mobNum}</td>
                      <td className="tc">{item.tranDate}</td>
                      <td className="tc">{item.operator}</td>
                      <td className="tc">
                        {item.tranStatus}
                        {item.tranStatus === "PROCESSING" ? (
                          <span>
                            <i
                              className="fas fa-sync ml-3 mr-3 pointer"
                              onClick={() => {
                                handleDelete(item.tranID);
                              }}
                            ></i>
                          </span>
                        ) : null}
                      </td>
                      <td className="tc" id="totAmt">
                        {item.amount}
                      </td>
                      <td className="tc">{item.balance}</td>
                      <td className="tc">
                        {item.coCommission === null
                          ? "0.0"
                          : item.tranStatus === "PROCESSING" ||
                            item.tranStatus === "FAIL"
                          ? "0.0"
                          : item.coCommission}
                      </td>
                      {/* <td className="tc">
                                                <span className="editspan badge ml-1">
                                                    View
                                                </span>
                                                {" "}
                                                <span className={reportFormValue.status === "PROCESSING" || item.tranStatus === "PROCESSING" ? "rejectspan badge" : "d-none"}
                                                    onClick={() => { handleDelete(item.tranID) }}>
                                                    Reverse
                                                </span>

                                            </td> */}
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid transparent",
                    }}
                  ></td>
                  <td
                    style={{
                      borderRight: "1px solid transparent",
                      borderLeft: "1px solid transparent",
                    }}
                  ></td>
                  <td
                    style={{
                      borderRight: "1px solid transparent",
                      borderLeft: "1px solid transparent",
                    }}
                  ></td>
                  <td
                    style={{
                      borderRight: "1px solid transparent",
                      borderLeft: "1px solid transparent",
                    }}
                  ></td>
                  <td
                    style={{
                      borderRight: "1px solid transparent",
                      borderLeft: "1px solid transparent",
                    }}
                  ></td>
                  <td
                    style={{
                      borderRight: "1px solid transparent",
                      borderLeft: "1px solid transparent",
                    }}
                  ></td>
                  <th>Total</th>
                  <td className="tc fw-bold">{amount}</td>
                  <td className="tc fw-bold">{balance}</td>
                  <td className="tc fw-bold">{coCommission.toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>

        {delPopup.show && (
          <ReversePop
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="table-responsive br-3">
        <table className="table table-bordereds text-nowrap mb-0">
          <thead>
            <tr className="tableHead">
              <td className="tl">S.N.</td>
              <td className="tc">Trans. ID</td>
              <td className="tl">Member</td>
              <td className="tc">Date</td>
              <td className="tl">Operator</td>
              <td className="tl">Status</td>
              <td className="tl">Amount</td>
              <td className="tl">Balance</td>
              <td className="tc">Comm. Earned</td>
            </tr>
          </thead>
        </table>
        <div className="text-center mt-4">
          <p>No data found</p>
        </div>
      </div>
    );
  }
};

export default ReportTable;
