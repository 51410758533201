import React, { useState, useEffect, useContext } from 'react'
import CryptoJS from "crypto-js";
import SidebarContext from '../../sidebarcontext/SidebarContext';
import UseHttp from '../../hooks/Use-http.jsx'
import UserContext from './UserContext';


function UserState(props) {
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { url, setMsg } = useContext(SidebarContext)
    const { sendRequest } = UseHttp();
    const [loading, setLoading] = useState(true);

    const [userFormError, setUserFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [editIsSubmit, setEditIsSubmit] = useState(false);


    const userinfo = JSON.parse(localStorage.getItem("userInfo"));


    // For popup
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    const [editModal, setEditModal] = useState(false);

    const editToggleModal = () => {
        setEditModal(!editModal);
    };



    const uservalue = {
        userName: '',
        fullName: '',
        address: '',
    };
    const [userFormValue, setUserFormValue] = useState(uservalue);




    //API to insert User data
    const fetchdata = async () => {

        const dataForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            IsActive: "A",
            UserName: userFormValue.userName,
            MacID: "PC-Mijal",
            FullName: userFormValue.fullName,
            Address: userFormValue.address,
            RoleCd: "000",
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"
        }

        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const dts = {
            EncrytedText: encrypted.toString(),
        };
        const response = await fetch(`${url}/UserMgnt/CreateUser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const pay = await response.json();


        if (pay.statuS_CODE === "0") {
            getUserList();
            setMsg({
                msg: pay.message,
                type: "alert alert-success",
            });
        } else {
            setMsg({
                msg: pay.message,
                type: "alert alert-danger",
            });
        }

    };

    const [userList, setUserList] = useState([])

    //API to hit User list

    useEffect(() => {
        getUserList();
    }, [])



    const getUserList = () => {
        const listForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            // IsActive: "Y",
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345",
        };
        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(listForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        const dts = {
            EncrytedText: encrypted.toString(),
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts)
        };
        fetch(`${url}/UserMgnt/UserList`, requestOptions)
            .then((result) => {
                result.json().then((resp) => {

                    if (resp.statuS_CODE === "0") {
                        setUserList(resp.lstUser)
                        setLoading(false)
                    }
                    else {
                        setUserList({})
                        setLoading(false)

                    }
                })
            })
    };


    //To Edit User 

    const [comId, setComId] = useState("")

    const handleEdit = (datas) => {
        editToggleModal();
        setComId(datas.userCode)
        setUserFormValue({
            userName: datas.userName,
            fullName: datas.fullName,
            address: datas.userAddress,
        })

    }


    //API to insert User data
    const editdata = async () => {

        const dataForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            // IsActive: "Y",
            UserName: userFormValue.userName,
            MacID: "PC-Mijal",
            FullName: userFormValue.fullName,
            Address: userFormValue.address,
            RoleCd: "000",
            UserID: comId.toString(),
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"
        }

        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const dts = {
            EncrytedText: encrypted.toString(),
        };
        const response = await fetch(`${url}/UserMgnt/UpdateUser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const pay = await response.json();


        if (pay.statuS_CODE === "0") {
            getUserList();
            setMsg({
                msg: pay.message,
                type: "alert alert-success",
            });
        } else {
            setMsg({
                msg: pay.message,
                type: "alert alert-danger",
            });
        }

    };


    // API to hit User Status

    const stateInitial = [];
    const [newStat, setNewStat] = useState(stateInitial);

    const deactivateNotice = async (notID, activeStatus) => {

        const formData = {
            CoOperativeCode: "ES25",
            UserID: notID,
            IsActive: activeStatus,
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"
        }
        if (activeStatus === "A") {
            formData.IsActive = "I"
        } else {
            formData.IsActive = "A"
        }
        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(formData),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );


        const dts = {
            EncrytedText: encrypted.toString(),
        };
        const response = await fetch(`${url}/UserMgnt/UpdateUser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const tole = await response.json();

        if (tole.statuS_CODE === "0") {
            getUserList();
            let statsN = JSON.parse(JSON.stringify(newStat));
            let pitchStatus;

            if (formData.IsActive === "A") {
                pitchStatus = "Activated";

            } else if (formData.IsActive === "I") {
                pitchStatus = "Deactivated";
            }

            setNewStat(statsN)
            setMsg({
                msg: `User ${pitchStatus} Successfully`,
                type: "alert alert-success",
            });
        } else {
            setMsg({
                msg: tole.message,
                type: "alert alert-danger",
            });
        }
    }





    return (
        <UserContext.Provider value={{ modal, setModal, toggleModal, userFormError, setUserFormError, isSubmit, setIsSubmit, uservalue, userFormValue, setUserFormValue, editModal, setEditModal, editIsSubmit, setEditIsSubmit, editToggleModal, handleEdit, fetchdata, userList, editdata, deactivateNotice }}>{props.children}</UserContext.Provider>
    )
}

export default UserState;

