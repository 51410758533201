import React from "react";

export default function ViewCandidate({ viewCandidate, closeViewPopup }) {
  return (
    <>
      <div className="container-fluid basicform">
        <div className="row">
          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {" "}
                  FullName{" "}
                </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  {viewCandidate.FullName}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {" "}
                  Email{" "}
                </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  {viewCandidate.Email}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {" "}
                  Contact{" "}
                </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  {viewCandidate.Contact}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {" "}
                  Message{" "}
                </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  {viewCandidate.Msg}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}> CV </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  <a
                    style={{
                      color: "var(--MainColor)",
                      textDecoration: "underline",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={viewCandidate.CV}
                  >
                    View
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {" "}
                  Cover{" "}
                </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  <a
                    style={{
                      color: "var(--MainColor)",
                      textDecoration: "underline",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={viewCandidate.Cover}
                  >
                    View
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}> Via </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  {viewCandidate.Via}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div style={{ padding: "0px 5px" }}>
                <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {" "}
                  Created Date{" "}
                </h4>
                <p style={{ fontSize: "12px", color: "#212529" }}>
                  {viewCandidate.CreatedDate}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose"></div>
        <div>
          {/* <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> */}
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeViewPopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
