import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import CryptoJS from "crypto-js";
import QRTransferContext from "./QRTransferContext";

function QRTransferState(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { url, setMsg } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);

  const currentDate= new Date();
  const threeMonthsBefore = new Date(currentDate);
  threeMonthsBefore.setMonth(currentDate.getMonth() - 3);

  const formattedDate = threeMonthsBefore.toISOString().split('T')[0];

  const internalvalue = {
    fromDate: formattedDate,
    toDate: currentDate,
    status: "",
  };

  const [internalFormValue, setInternalFormValue] = useState(internalvalue);
  //API to hit Report list

  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    TopupList();
  }, [internalFormValue]);

  const TopupList = () => {
    const listForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      userName: userinfo.UserName,
      TxnID: "",
      fromDate: internalFormValue.fromDate,
      toDate: internalFormValue.toDate,
      Status: internalFormValue.status,
      OperatorName: "QR_SETTLEMENT",
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };

    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(listForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts),
    };
    fetch(
      `${url}/MoneyTransfer/MoneyTransferReportwallet`,
      requestOptions
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.statuS_CODE === "0") {
          setReportList(resp.lstMoneyTransferReport);
          setLoading(false);
        } else {
          setReportList([]);
          setLoading(false);
        }
      });
    });
  };

  return (
    <QRTransferContext.Provider
      value={{
        internalFormValue,
        setInternalFormValue,
        internalvalue,
        reportList,
        loading,
      }}
    >
      {props.children}
    </QRTransferContext.Provider>
  );
}

export default QRTransferState;
