import React, { useContext, useState, useEffect } from 'react'
import NotificationContext from '../contextfolder/NotificationContext/NotificationContext';
import ReportContext from '../contextfolder/ReportContext/ReportContext';

export default function NotificationForm(props) {

    const context = useContext(NotificationContext)

    const { toggleModal, notificationFormError, setNotificationFormError, isSubmit, setIsSubmit, notificationvalue, notificationFormValue, setNotificationFormValue, notify, setNotify, fetchdata } = context;

    const { depoUserList } = useContext(ReportContext)



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setNotificationFormValue({ ...notificationFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setNotificationFormError(validate(notificationFormValue));
        setIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(notificationFormError).length === 0 && isSubmit) {
            fetchdata(notificationFormValue)
            setNotificationFormValue(notificationvalue)
            setIsSubmit(false);
            toggleModal();
        }

    }, [notificationFormError]);



    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setNotificationFormValue(notificationvalue)
        setNotificationFormError({})
        setIsSubmit(false);
    }

    const validate = (values) => {
        const errors = {};
        if (notificationFormValue.memberList !== "-1") {
            if (!values.name) {
                errors.name = "Required";
            }
        }
        if (!values.description) {
            errors.description = "Required";
        }
        if (!values.memberList) {
            errors.memberList = "Required";
        }

        return errors;
    };

    const handleOnChange = (e) => {
        setNotify(!notify)
    };


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="memberList">To</label>
                        <select id="memberList" name="memberList"
                            onChange={handleChange}
                            value={notificationFormValue.memberList} className="form-control form-control-sm mb-1" style={{ fontSize: '12px' }}>
                            <option value="" selected>Select Option</option>
                            <option value="-1">All</option>

                            {depoUserList.map((item, i) => <option key={i} value={item.memID}>{item.userName}</option>)}
                        </select>
                        <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.memberList}</p>

                    </div>

                    {/* {notificationFormValue.memberList !== "-1" ? */}

                        <div className="form-group">
                            <label className="form-label" htmlFor="name">Name<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={notificationFormValue.name} id="name" name="name" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.name}</p>
                        </div>

                        {/* : <></> */}

                    <div className="form-group">
                        <label className="form-label" htmlFor="description">Description<sup className="sup-col">*</sup></label>
                        <textarea type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={notificationFormValue.description} id="description" name="description" style={{ fontSize: "12px" }} />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.description}</p>
                    </div>

                    <input
                        type="checkbox"
                        // className="form-check-input"
                        id="exampleCheck2"
                        name="allowApp"
                        onChange={handleOnChange}
                        checked={notify}
                    />
                    <label className="form-check-label" for="exampleCheck2" style={{ fontSize: "12px", color: "#212529", paddingLeft: "5px" }}>
                        Notify User
                    </label>


                </form>

            </div>
            <div className=" FormFooter">
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div>

        </>

    )
}
