import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import CryptoJS from "crypto-js";
import RemittanceContext from "./RemittanceContext";

function RemittanceState(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { url, setMsg } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);

  const [chooseMember, setChooseMember] = useState("");

  const internalvalue = {
    fromDate: "",
    toDate: "",
    status: "",
  };

  const [internalFormValue, setInternalFormValue] = useState(internalvalue);

  //API to hit Report list

  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    TopupList();
  }, [
    chooseMember,
    internalFormValue.status ||
      internalFormValue.fromDate ||
      internalFormValue.toDate,
  ]);

  const TopupList = () => {
    const listForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      userName: chooseMember,
      fromDate: internalFormValue.fromDate,
      toDate: internalFormValue.toDate,
      Status: internalFormValue.status,
      OperatorID: "IMEREMIT",
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };

    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(listForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts),
    };
    fetch(`${url}/MoneyTransfer/MoneyTransferReport`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.statuS_CODE === "0") {
            setReportList(resp.lstMoneyTransferReport);
            setLoading(false);
          } else {
            setReportList([]);
            setLoading(false);
          }
        });
      }
    );
  };

  //Everything to pop up and delete the slider list
  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();

  // To delete the Account List

  const handleDelete = async (data) => {
    setACData(data);

    setDelPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    // const dataForm = {
    //   CoOperativeCode: userinfo.coOperativeCode,
    //   UserName: "9868348282",
    //   ID: Acdata,
    //   RefNum: "",
    //   IsEncryptReq: "N",
    //   TimeStamp: "2022-05-02T01:35:44.345",

    // };

    const dataForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      UserName: "9801085152",
      TxnID: Acdata,
      RefNum: "",
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };

    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(dataForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const response = await fetch(
      "https://esnep.com/MblPayPanel/BalanceTransfer/CheckProcessingTxns",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: JSON.stringify(dts), //dts
      }
    );
    const tole = await response.json();

    if (tole.statuS_CODE === "01") {
      TopupList();
      setMsg({
        msg: tole.message,
        type: "alert alert-danger",
      });
    } else {
      setMsg({
        msg: tole.message,
        type: "alert alert-success",
      });
    }
    setDelPopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and delete the report list ends

  return (
    <RemittanceContext.Provider
      value={{
        handleDeleteTrue,
        handleDelete,
        delPopup,
        handleDeleteFalse,
        chooseMember,
        setChooseMember,
        internalFormValue,
        setInternalFormValue,
        internalvalue,
        reportList,
        loading,
      }}
    >
      {props.children}
    </RemittanceContext.Provider>
  );
}

export default RemittanceState;
