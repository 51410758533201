import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import ReportContext from "./ReportContext";
import CryptoJS from "crypto-js";
import JsPDF from "jspdf";
import { Fetchdata } from "../../hooks/getData";

function ReportState(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { url, setMsg } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);

  const today = new Date();

  // const defaultValue = new Date(date).toISOString().split('T')[0]

  const year = today.getFullYear();
  var month = today.getMonth() + 1;
  if (month <= 9) {
    month = "0" + month;
  }
  var day = today.getDate();
  if (day <= 9) {
    day = "0" + day;
  }
  // APi to export pdf

  const generatePDF = () => {
    const report = new JsPDF("portrait", "px", "a2");
    report.html(document.querySelector("#table-to-xls")).then(() => {
      report.save("report.pdf");
    });
  };

  // const defaultValue = month + "/" + day + "/" + year
  //

  const [chooseOption, setChooseOption] = useState("");

  const [chooseMember, setChooseMember] = useState("");

  const [chooseOperator, setChooseOperator] = useState("");

  const reportvalue = {
    fromDate: "",
    toDate: "",
    status: "",
  };

  const [reportFormValue, setReportFormValue] = useState(reportvalue);

  //API to hit Report list

  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    TopupList();
  }, [
    chooseMember,
    chooseOperator,
    reportFormValue.fromDate,
    reportFormValue.toDate,
    reportFormValue.status ||
      reportFormValue.fromDate ||
      reportFormValue.toDate,
  ]);

  const TopupList = () => {
    const listForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      Username: chooseMember,
      FromDate: reportFormValue.fromDate,
      // FromDate: "2022-05-05",
      ToDate: reportFormValue.toDate,
      OperatorName: chooseOperator,
      // ToDate: "2022-06-04",
      Status: reportFormValue.status,

      // Status: "SUCCESS",
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };
   

    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(listForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts),
    };
    fetch(`${url}/BalanceTransfer/TopupReport`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.statuS_CODE === "0") {
            setReportList(resp.lstGetTopUpData);
            setLoading(false);
          } else {
            setReportList([]);
            setLoading(false);
          }
        });
      }
    );
  };

  //API to hit Cooperative list
  const [operatorList, setOperatorList] = useState([]);

  useEffect(() => {
    oprList();
  }, []);

  const oprList = () => {
    const params = {
      Type: "POST",
      FetchURL: `${url}/Category/GetOperatorList`,
    };

    Fetchdata(params).then(function (result) {
      if (result.statuS_CODE === "0") {
        const postResult = result.lstOperator ? result.lstOperator : "";
        setOperatorList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOperatorList([]);
      }
    });
  };

  // API to post Deposit User List / Member

  const [depoUserList, setDepoUserList] = useState([]);

  useEffect(() => {
    DepositorList();
  }, []);

  const DepositorList = () => {
    const listForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(listForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts),
    };
    fetch(`${url}/UserMgnt/DepositorUserList`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.statuS_CODE === "0") {
            setDepoUserList(resp.lstDepositUser);
            setLoading(false);
          } else {
            setDepoUserList([]);
            setLoading(false);
          }
        });
      }
    );
  };

  //Everything to pop up and delete the slider list
  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();

  // To delete the Account List

  const handleDelete = async (data) => {
    setACData(data);

    setDelPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      UserName: "9801085152",
      TxnID: Acdata,
      RefNum: "",
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };

    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(dataForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };

    const response = await fetch(`${url}/BalanceTransfer/CheckProcessingTxns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts), //dts
    });
    const tole = await response.json();

    if (tole.statuS_CODE === "01") {
      TopupList();
      setMsg({
        msg: tole.message,
        type: "alert alert-danger",
      });
    } else {
      setMsg({
        msg: tole.message,
        type: "alert alert-success",
      });
    }
    setDelPopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and delete the report list ends

  return (
    <ReportContext.Provider
      value={{
        chooseOption,
        setChooseOption,
        chooseMember,
        setChooseMember,
        chooseOperator,
        setChooseOperator,
        reportFormValue,
        setReportFormValue,
        reportvalue,
        reportList,
        operatorList,
        loading,
        depoUserList,
        handleDeleteTrue,
        handleDeleteFalse,
        delPopup,
        handleDelete,
        generatePDF,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
}

export default ReportState;
