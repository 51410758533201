import React, { useContext, useState, useEffect } from 'react'
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
import SliderContext from '../contextfolder/SliderContext/SliderContext';

export default function SliderForm(props) {

    const context = useContext(SliderContext)

    const { toggleModal, sliderFormError, setSliderFormError, isSubmit, setIsSubmit, slidervalue, sliderFormValue, setSliderFormValue, typeFile, setTypeFile, image, setImage, isUploaded, setIsUploaded, fetchdata } = context;



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setSliderFormValue({ ...sliderFormValue, [name]: value });
    };

    const formSubmit = (e) => {



        e.preventDefault();
        // setSliderFormError(validate(image));
        setIsSubmit(true);

        fetchdata(image)
        setImage("");
        setIsUploaded(false)
    }
    useEffect(() => {
        if (Object.keys(sliderFormError).length === 0 && isSubmit) {
            // if (isSubmit) {
            // fetchdata(sliderFormValue, image)

            fetchdata(image)
            // setSliderFormValue(slidervalue)
            setImage("")
            toggleModal();

        }
        setIsSubmit(false);

    }, [sliderFormError]);



    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setSliderFormValue(slidervalue)
        setSliderFormError({})
        setImage("")
    }

    const validate = (values) => {
        const errors = {};
        if (!values.image) {
            errors.image = "Required";
        }


        return errors;
    };

    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            setTypeFile(e.target.files[0].type);
            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    {/* <div className="form-row">


                        <div className="form-group col-md-12">
                            <label className="form-label" htmlFor="order">Order<sup className="sup-col">*</sup></label>
                            <select id="order" name="order"
                                onChange={handleChange}
                                value={sliderFormValue.order} className="form-control form-control-sm mb-1" style={{ fontSize: '12px' }}>
                                <option value="none" selected>Select Order Note</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>

                            </select>
                            <p className="errormsg " style={{ marginBottom: "0" }}>{sliderFormError.order}</p>
                        </div>

                    </div> */}

                    <div className="form-group  ">
                        <div className="form-label" htmlFor="text">
                            Upload Image
                        </div>
                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!isUploaded ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img

                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input

                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img

                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img

                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <p className="errormsg " style={{ marginBottom: "0" }}>{sliderFormError.image}</p> */}
                </form>

            </div>
            <div className=" FormFooter">
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div>

        </>

    )
}
