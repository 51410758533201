import React, { useContext, useState, useRef } from "react";
import "../../css/Commission.css";
import "../../css/Notification.css";
import InternalFundContext from "../contextfolder/InternalFundContext/InternalFundContext";
import ReportContext from "../contextfolder/ReportContext/ReportContext";
import InternalFundTable from "./InternalFundTable";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { GetNepaliDate } from "../hooks/dateConvertor";

function InternalFund() {
  const { depoUserList } = useContext(ReportContext);

  const {
    chooseMember,
    setChooseMember,
    internalFormValue,
    setInternalFormValue,
  } = useContext(InternalFundContext);

  const handleChanges = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseMember(value);
  };

  const handleReport = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setInternalFormValue({ ...internalFormValue, [name]: value });
  };

  const handleDate = ({ adDate }) => {
    setInternalFormValue({ ...internalFormValue, fromDate: adDate });
  };
  const handleDate1 = ({ adDate }) => {
    setInternalFormValue({ ...internalFormValue, toDate: adDate });
  };

  const [searchTerm, setSearchTerm] = useState("");

  // For print purpose

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "report-data",
    // onAfterPrint: () => alert("print success")
  });

  return (
    <>
      <div className=" col-lg-12 col-sm-12">
        <div className="row">
          <div className="col-lg-2  col-md-3 col-sm-2 ">
            <label>Member</label>
            <select
              style={{ fontSize: "11px" }}
              name="snotifiaction"
              value={chooseMember}
              onChange={handleChanges}
              className="form-control form-control-sm mb-1"
            >
              <option selected disabled style={{ fontSize: "11px" }}>
                Select Option
              </option>
              <option value="">All</option>
              {depoUserList.map((item) => (
                <option key={item.memID} value={item.userName}>
                  {item.fullName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div
            className="col-lg-2 col-md-3 col-sm-2 "
            style={{ padding: "5px" }}
          >
            <label>From Date</label>
            {/* <input type="date"
                            name="fromDate"
                            className="form-control form-control-sm mb-1"
                            value={internalFormValue.fromDate}
                            onChange={handleReport}
                        /> */}
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={GetNepaliDate(internalFormValue.fromDate)}
              placeholder={GetNepaliDate(internalFormValue.fromDate)}
              onChange={handleDate}
              hideDefaultValue={true}
            />
          </div>

          <div
            className="col-lg-2 col-md-3 col-sm-2 "
            style={{ padding: "5px" }}
          >
            <label>To Date</label>
            {/* <input type="date"
                            name="toDate"
                            className="form-control form-control-sm mb-1"
                            value={internalFormValue.toDate}
                            onChange={handleReport}
                        /> */}
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={internalFormValue.toDate}
              onChange={handleDate1}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-2 ">
            <label>Status</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm mb-1"
              value={internalFormValue.status}
              onChange={handleReport}
            >
              <option selected disabled value="0" style={{ fontSize: "11px" }}>
                Select Status
              </option>
              <option value="">All</option>
              <option value="COMPLETED">Completed</option>
              <option value="PENDING">Pending</option>
              <option value="FAIL">Failed</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-2 print-pdf-internal">
            <i
              className="fa fa-print account-bar"
              aria-hidden="true"
              onClick={handlePrint}
              style={{ fontSize: "18px" }}
            ></i>

            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="fa fa-file-excel-o"
              table="table-to-xls"
              filename="report"
              sheet="tablexls"
              buttonText=""
            >
              <i class="fa fa-file-excel-o" aria-hidden="true"></i>
            </ReactHTMLTableToExcel>
          </div>
          <div
            className="col-lg-2 col-md-3 col-sm-2 search-icon-position-internal"
            style={{ padding: "5px" }}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <i
              style={{ color: "#999", top: "14px" }}
              className="fas fa-search searchIconPosition"
            ></i>
          </div>
        </div>
      </div>
      {
        <div className="row">
          <div className="">
            <div className="col-lg-12 p-1">
              <InternalFundTable
                searchTerm={searchTerm}
                componentRef={componentRef}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default InternalFund;
