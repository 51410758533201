import React, { useState, useEffect, useContext } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../App.css";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import bankingimage from "../images/banking.png";
import "../css/login.css";
import AuthContext from "./auth-context";
import CryptoJS from "crypto-js";

export default function Login() {
  const authCtx = useContext(AuthContext);
  const apiKey = process.env.REACT_APP_KEY;
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const initalvalue = { UserName: "", Password: "", CoopID: "" };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const url = process.env.REACT_APP_URL;

  const date = new Date();
  let year = date.getFullYear();

  let navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
  };

  let location = useLocation();

  const fetchdata = async (dataToSend) => {
    const response = await fetch(`${url}/Login/UserLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Signature: apisignature,
      },
      body: JSON.stringify(dataToSend),
    });
    const tole = await response.json();

    return tole;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const dataForm = {
        CoOperativeCode: formValues.CoopID,
        UserName: formValues.UserName,
        PassCode: formValues.Password,
        IsEncryptReq: "Y",
        TimeStamp: "2022-05-02T01:35:44.345",
      };
      var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
      var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

      var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      const dataToSend = {
        EncrytedText: encrypted.toString(),
      };

      fetchdata(dataToSend)
        .then(function (result) {
          // const code = CryptoJS.AES.decrypt(result.message, key, {
          //     iv: iv,
          //     mode: CryptoJS.mode.CBC,
          //     padding: CryptoJS.pad.Pkcs7,
          // });
          // const response = JSON.parse(code.toString(CryptoJS.enc.Utf8))
          //

          const res = result;
          const results = result.statuS_CODE;

          if (results === "0") {
            const userInfo = {
              UserID: res.userID,
              UserName: res.userName,
              UserType: res.userType,
              categoryID: res.categoryID,
              customerAddress: res.customerAddress,
              customerName: res.customerName,
              coOperativeName: res.coOperativeName,
              coOperativeAddress: res.coOperativeAddress,
              coOperativeCode: res.coOperativeCode,
              coOperativeImg: res.coOperativeImg,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            authCtx.login(res.userID);
            navigate("/dashboard");
            setIsSubmit(false);
          } else {
            setIsSubmit(false);
            setformErrors({ ...formErrors, errorv: res.message });
          }
        })

        .catch((error) => {
          setIsSubmit(false);
          setformErrors({ ...formErrors, errorv: "Error" });
        });
    } else {
      setIsSubmit(false);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.UserName) {
      errors.UserName = "Username is required";
    }
    if (!values.CoopID) {
      errors.CoopID = "Id is required";
    }
    // if (!values.password) {
    //     errors.password = "Password  is required";
    // } else if (values.password.length < 4) {
    //     errors.password = "Password must ve more than 4 Characters"
    // } else if (values.password.length > 10) {
    //     errors.password = "Password cannot exceed more than 10 charecters"
    // }
    return errors;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return authCtx.isLoggedIn ? (
    <Navigate to="/dashboard" replace state={{ from: location }} />
  ) : (
    <>
      <div className="container login-container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 login-form-1 ">
            <div className="text-center">
              <h5>Log in</h5>

              <p>Enter your valid credentials below</p>
            </div>
            <form>
              <p className="errormsg"> {formErrors.errorv}</p>

              <div className="form-group">
                <label htmlFor="text">Cooperative Code</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="CoopID"
                  onChange={handleChange}
                  value={formValues.CoopID}
                  placeholder="Enter your ID"
                />
                <p className="errormsg">{formErrors.CoopID}</p>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Username</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="UserName"
                  value={formValues.UserName}
                  onChange={handleChange}
                  placeholder="Enter your username"
                />
                <p className="errormsg">{formErrors.UserName}</p>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control form-control-sm"
                  name="Password"
                  value={formValues.Password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
                <p className="errormsg">{formErrors.Password}</p>
              </div>
              <div className="form-group ">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btnSubmit"
                >
                  {isSubmit ? <span>Loading ...</span> : <span>Login</span>}
                </button>
              </div>
            </form>
            <div className="text-center">
              <p style={{ marginTop: "10px" }}>
                By{" "}
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => openInNewTab("https://easysoftware.com.np/")}
                >
                  <span style={{ color: "red" }}>Easy</span>{" "}
                  <span style={{ color: "#0079bf" }}>Software</span>
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md- 6 col-sm-6 login-form-2 text-center">
            <h3>Welcome to the easy software</h3>
            <h6>Banking Software</h6>
            <OwlCarousel items={1} margin={20} autoplay={true}>
              <div className="item">
                <img src={bankingimage} alt="bankimage" />{" "}
              </div>
              <div className="item">
                <img src={bankingimage} alt="bankimage" />
              </div>
              <div className="item">
                <img src={bankingimage} alt="bankimage" />
              </div>
            </OwlCarousel>
            <p>2021 Easy Software Pvt.Ltd. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
}
