import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Job from "./Job";
import Candidate from "./Candidate/Candidate";
import Company from "./Company/Company";
import { FetchJobdata } from "../hooks/getJobData";
import { ToastContainer, toast } from "react-toastify";

const EliteJobDashboard = () => {
  const { AcademicYear, todayDate, jobURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
    });
  };

  const handleSubmit = () => {
    const dataForm = {
      ComID: userinfo.coOperativeCode,
      Flag: "U",
      Email: userinfo.Email,
      Address: userinfo.SchAddress,
      District: "1",
      IndustryID: "1",
      Website: userinfo.SchWebsite,
      Bio: userinfo.Msg,
      Latitude: userinfo.LocLat,
      Longitude: userinfo.LocLong,
      Facebook: userinfo.SchFb,
      Instagram: userinfo.SchInsta,
      Linkedin: userinfo.SchLinkedin,
      Logo: userinfo.SchLogo,
      Telephone: userinfo.SchTel,
      FetchURL: `${jobURL}api/job/com-sync`,
      Type: "POST",
    };

    FetchJobdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
       
          <div className="header--section">
            <div className="fontHeader">
              {activeTab.tab1
                ? "Job"
                : activeTab.tab2
                ? "Candidate"
                : activeTab.tab3
                ? "Company Profile"
                : ""}
            </div>
           
          </div>
       
        <hr />

        {/* <div className="tabview ">
          <div className="insidePopup--div uk-flex uk-flex-between uk-flex-middle uk-width-1-1">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Job{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Candidate{" "}
              </li>
              <li
                className={activeTab.tab3 === true ? "active" : ""}
                onClick={handleTab3}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Company Profile{" "}
              </li>
            </ul>

            {activeTab.tab3 && (
              <div>
                <button
                  className="btn btn-sm btn-cmpy"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "14px",
                  }}
                  onClick={handleSubmit}
                >
                  Sync Company
                </button>
              </div>
            )}
          </div>
        </div> */}

        <div className="content-section main-content">
          {activeTab.tab1 && <Job handleSubmit={handleSubmit} />}
          {/* {activeTab.tab2 && <Candidate />}
          {activeTab.tab3 && <Company />} */}
        </div>
      </div>
    </>
  );
};

export default EliteJobDashboard;
