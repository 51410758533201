import React, { useState, useRef } from 'react'
import '../../css/Commission.css';
import OperatorTable from './OperatorTable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useReactToPrint } from "react-to-print";



function Operator() {
    const [searchTerm, setSearchTerm] = useState("");



    // For print purpose

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'report-data',
        // onAfterPrint: () => alert("print success")
    });

    return (
        <>


            <div className=" col-lg-12 col-sm-12">

                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-3 p-2 Search">
                        <div className="dashboard-search-field">
                            <input type="text" placeholder="Search" value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }} style={{ border: "none" }} />
                            <i style={{ paddingTop: "7px", paddingRight: "5px", color: "#999" }} className="fas fa-search searchIconPosition"></i>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-2 print-pdf-operator" >


                        <i className="fa fa-print account-bar" aria-hidden="true" onClick={handlePrint} style={{ fontSize: "18px" }}></i>

                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="fa fa-file-excel-o"
                            table="table-to-xls"
                            filename="report"
                            sheet="tablexls"
                            buttonText="" >
                            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                        </ReactHTMLTableToExcel>

                    </div>



                </div>
            </div>
            {<div className="row">
                <div className="">
                    <div className="col-lg-12 p-1">

                        <OperatorTable searchTerm={searchTerm} componentRef={componentRef} />

                    </div>
                </div>

            </div>}


        </>
    )
}

export default Operator