import React, { useContext, useState } from 'react'
import '../../css/Commission.css';
import NotificationContext from '../contextfolder/NotificationContext/NotificationContext';
import ReportContext from '../contextfolder/ReportContext/ReportContext';
import NotificationForm from './NotificationForm';
import NotificationTable from './NotificationTable';



function Notification() {

    const context = useContext(NotificationContext)
    const { modal, toggleModal, setNotificationFormValue, setNotificationFormError, notificationvalue, chooseOption, setChooseOption, chooseMember, setChooseMember, setIsSubmit } = context;




    // const handleChange = (e) => {
    //     const target = e.target;
    //     const name = target.name;
    //     const value = target.value;
    //     setChooseOption(value);
    // };

    const handleMember = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setChooseMember(value);
    };

    const { depoUserList } = useContext(ReportContext)

    const [searchTerm, setSearchTerm] = useState("");


    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setNotificationFormValue(notificationvalue)
        setNotificationFormError({})
        setIsSubmit()
    }

    const addnew = (e) => {
        e.preventDefault();
        toggleModal();
        setIsSubmit()
        setNotificationFormValue(notificationvalue)
    }



    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
                <div className="header--section">

                    <div className="fontHeader">Notification</div>

                    <div className='header--button'>
                        <button className="btn btn-cmpy btn-sm" onClick={addnew}> Add Notification +</button>
                    </div>

                </div>
                <hr />
                <section className="content-section main-content">
                    <div className="content">
                        <div className=" col-lg-12 col-sm-12">

                            <div className="row" >
                                {/*   <div className="col-lg-2  col-md-3 col-sm-2 " style={{ padding: "5px" }}>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="snotifiaction"
                                        className="form-control form-control-sm mb-1"
                                        value={chooseOption}
                                        onChange={handleChange}
                                    >
                                        <option value="0" disabled selected style={{ fontSize: "11px" }}>
                                            Select Option
                                        </option>
                                        <option value="-1">All</option>
                                        <option value="member" >Member</option>
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div> */}

                                {/* {chooseOption === "member" && ( */}

                                <div className="col-lg-2 col-md-3 col-sm-2 " style={{ padding: "5px" }}>
                                <label>Member</label>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="snotifiaction"
                                        className="form-control form-control-sm mb-1"
                                        value={chooseMember}
                                        onChange={handleMember}
                                    >
                                        <option value="" disabled selected style={{ fontSize: "11px" }}>
                                            Select Option
                                        </option>
                                        <option value="-1">All</option>
                                        {depoUserList.map((item) => (<option key={item.memID} value={item.userName}>{item.fullName}</option>))}
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>
                                {/* )} */}


                                {/* <div className={chooseOption === "member" ? " col-lg-2 offset-lg-6 offset-md-3 offset-sm-0 col-md-3 col-sm-3 align-self-end Search " : "col-lg-2 offset-lg-8 offset-md-6 offset-sm-7 col-md-3 col-sm-3 align-self-end Search"} style={{ padding: "5px" }}> */}

                                <div className="col-lg-2 offset-lg-8 offset-md-6 offset-sm-7 col-md-3 col-sm-3 align-self-end Search" style={{ padding: "5px" }}>
                                    <div className="dashboard-search-field justify-content-end">
                                        <input type="text" placeholder="Search" value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                            }} style={{ border: "none" }} />
                                        <i style={{ paddingTop: "7px", paddingRight: "5px", color: "#999" }} className="fas fa-search searchIconPosition"></i>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="">
                                <div className="col-lg-12 p-1">

                                    <NotificationTable searchTerm={searchTerm} />

                                </div>
                            </div>

                        </div>


                    </div>
                </section>
            </div>

            {modal && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="notification-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Add Notification</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <NotificationForm />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Notification