import React, { useContext, useEffect, useState } from 'react'
import SidebarContext from '../../sidebarcontext/SidebarContext';
import CryptoJS from "crypto-js";
import ChequeServiceContext from './ChequeServiceContext';


function ChequeServiceState(props) {
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { url, setMsg } = useContext(SidebarContext)
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    const [loading, setLoading] = useState(true);



    const [chooseMember, setChooseMember] = useState("")

    const [chequeType, setChequeType] = useState("")



    //API to hit Report list

    const [chequeServiceList, setChequeServiceList] = useState([]);


    useEffect(() => {
        ChequeService();
    }, [chequeType, chooseMember])



    const ChequeService = () => {
        const listForm = {

            CoOperativeCode: userinfo.coOperativeCode,
            UserID: chooseMember,
            ChequeType: chequeType,
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"

        };
        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(listForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        const dts = {
            EncrytedText: encrypted.toString(),
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts)
        };
        fetch(`${url}/Reports/ChequeRequest`, requestOptions)
            .then((result) => {
                result.json().then((resp) => {

                    if (resp.statuS_CODE === "0") {
                        setChequeServiceList(resp.lstChequeReqReport)
                        setLoading(false)
                    }
                    else {
                        setChequeServiceList([])
                        setLoading(false)

                    }
                })
            })
    };






    return (
        <ChequeServiceContext.Provider value={{ chooseMember, setChooseMember, loading, chequeType, setChequeType, chequeServiceList }}>{props.children}</ChequeServiceContext.Provider>
    )
}

export default ChequeServiceState;