import React, { Fragment, useContext, useEffect, useState } from "react";
import Sidebarone from "./Sidebarone";
import Uppersidebar from "./Uppersidebar";
import Footer from "./Footer";
import useWindowDimensions from "./hooks/UsewindowDimension.js";
import "../css/Layout.css";
import SidebarContext from "./sidebarcontext/SidebarContext";
import { Alert } from "./Alert";
// import OutsideAlerter from './hooks/OutsideAlerter';

export default function Layout(props) {
  const context = useContext(SidebarContext);
  const { menutoggle, setMenutoggle, msg, setMsg, alert } = context;

  const [smallscreen, setSmallscreen] = useState(false);

  const { height, width } = useWindowDimensions();

  const message = (msg) => {
    // 
    return msg;
  };

  useEffect(() => {
    if (width < 1100) {
      setMenutoggle(true);
      setSmallscreen(true);
    } else {
      setMenutoggle(false);
      setSmallscreen(false);
    }
  }, [width]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMsg({});
    }, 3000);
    return () => clearTimeout(timer);
  }, [msg]);

  return (
    <Fragment>
      <div className="container-fluid main-wrapper ">
        <div className="row">
          {smallscreen ? (
            <>
              <div className="col-lg-12 alpha--a">
                <div className={menutoggle ? "hide " : "col-lg-2 side-nav "}>
                  <Sidebarone />
                </div>

                <div
                  className={
                    menutoggle ? "hide " : "col-lg-12 alpha--a  background--a"
                  }
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                ></div>
              </div>

              <div
                className="col-lg-12 col-md-12  col-sm-12 col-12  d-flex flex-column min-vh-100 togglewidthsmall"
                id="upper-nav"
              >
                <Uppersidebar />
                {props.children}
                <div className="mt-auto">
                  <Footer />
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className={menutoggle ? "hide" : "col-lg-2 side-navfixed "}>
                <Sidebarone />
              </div>
              <div
                className={
                  menutoggle
                    ? "col-lg-12 col-md-12  col-sm-12 col-12  d-flex flex-column min-vh-100 togglewidthsmall"
                    : "col-lg-10 col-md-10 col-sm-10 col-10 d-flex flex-column min-vh-100 togglewidthlarge"
                }
                id="upper-nav"
              >
                <Uppersidebar />
                {props.children}
                <div className="mt-auto">
                  <Footer />
                </div>
              </div>{" "}
            </>
          )}
        </div>

        <div className={`${msg.type} alertMsg `}> {message(msg.msg)}</div>
      </div>

      <div className={`${alert.fade} col-lg-12 col-md-12 col-sm-12`}>
        <Alert trigger={alert} />
      </div>
    </Fragment>
  );
}
