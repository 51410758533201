import classes from "../css/abs.css";
import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import user from "../images/user.jpg";
import UsewindowDimension from "./hooks/UsewindowDimension.js";
import "../css/Sidebarone.css";
import SidebarContext from "./sidebarcontext/SidebarContext";
import AuthContext from "./auth-context";

export default function Sidebarone(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const UserName = userinfo.UserName;
  const CustomerName = userinfo.customerName;
  const CustomerImage = userinfo.CustomerImage;

  const context = useContext(SidebarContext);
  const { menutoggle, setMenutoggle, setAlert, logoutdata } = context;

  const [active, setActive] = useState(false);
  const handleLogoClick = (e) => {
    e.preventDefault();
    setActive(!active);
  };

  const handleMenuChange = () => {
    setMenutoggle(!menutoggle);
  };

  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const auth = localStorage.getItem("userInfo");

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function logOut(e) {
    e.preventDefault();
    setAlert({
      fade: "fade-in",
      msg: "Do you want to logout ?",
      type: "logout",
    });
  }
  function logoutFunction() {
    authCtx.logout();
    localStorage.clear();
    setAlert({
      fade: "fade-default",
      msg: "",
      type: "",
    });
    navigate("/login");
  }

  useEffect(() => {
    if (logoutdata) {
      logoutFunction();
    }
  }, [logoutdata]);

  function ChangePW(e) {
    e.preventDefault();
    setAlert({
      fade: "fade-in",
      msg: "",
      type: "change",
    });
  }

  const { width } = UsewindowDimension();
  const handleDrawer = () => {
    if (width < 1100) {
      setMenutoggle(!menutoggle);
    } else {
      setMenutoggle(menutoggle);
    }
  };

  return (
    <>
      {auth ? (
        <>
          <div className="menu">
            <div className="img ">
              <img src={logo} alt="" className="imgMain" />
              <span className="logoHeading">esPay</span>
              <span className="close-button" onClick={handleMenuChange}>
                <i className="fas fa-times"></i>
              </span>
            </div>
            <nav className="MainNavbar">
              <div className="mainNavUserInfo">
                <div>
                  <img className="userImg" src={user} alt="" />{" "}
                </div>
                <div className="userInfotext">
                  {" "}
                  <p className="mb-0">{CustomerName}</p>
                  <p className="mb-0" style={{ textAlign: "left" }}>
                    {UserName}
                  </p>
                </div>
              </div>
              <ul>
                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    to="/dashboard"
                    onClick={handleDrawer}
                  >
                    <i className="fa fa-tachometer mx-3 icon"></i>Dashboard
                  </NavLink>{" "}
                </li>

                {/* <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/profile" ><i className="bi bi-people mx-3 icon" ></i>Profile</NavLink> </li> */}

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/user"
                  >
                    <i className="bi bi-people mx-3 icon"></i>User
                  </NavLink>{" "}
                </li>

                {/* <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/commission" ><i className="fa fa-money mx-3 icon" ></i>Commission</NavLink> </li> */}

                {/* <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/cooperative" ><i className="far fa-calendar mx-3 icon"></i>Cooperative</NavLink> </li> */}

                <li
                  className="list-name"
                  data-bs-toggle="collapse"
                  href="#transaction"
                >
                  <Link to="#transaction">
                    <i className="fa fa-table mx-3 icon"></i>Transaction{" "}
                    <i
                      class="fas fa-angle-down "
                      style={{ float: "right", marginTop: "3px" }}
                    ></i>
                    <div className="collapse submenu-dropdown" id="transaction">
                      <Link
                        to="/report"
                        onClick={handleDrawer}
                        className="link"
                      >
                        <div className="sublist-name">Report</div>
                      </Link>
                      <Link
                        to="/operator"
                        onClick={handleDrawer}
                        className="link"
                      >
                        <div className="sublist-name">Summary</div>
                      </Link>
                    </div>
                  </Link>
                </li>

                {/* <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/report" ><i className="fa fa-table mx-3 icon" ></i>Transaction Report</NavLink> </li>

                            <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/operator" ><i className="fa fa-table mx-3 icon" ></i>Transaction Summary</NavLink> </li> */}

                {/* <li className="list-name"
                                data-bs-toggle="collapse"
                                href="#account"><Link to="#account"><i className="fa fa-table mx-3 icon" ></i>Report <i class="fas fa-angle-down " style={{ float: "right", marginTop: "3px" }}></i>
                                    <div
                                        class="collapse submenu-dropdown "
                                        id="account"
                                    >
                                        <Link to="/report" onClick={handleDrawer} className="link">
                                            <div className="sublist-name">Top Up Report</div>
                                        </Link>
                                        <Link to="/summary" onClick={handleDrawer} className="link">
                                            <div className="sublist-names">Summary</div>
                                        </Link>
                                        <Link to="/internal" onClick={handleDrawer} className="link">
                                            <div className="sublist-names">Fund Transfer</div>
                                        </Link>
                                    </div> </Link>
                            </li> */}

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/cheque-service"
                  >
                    <i className="fa fa-check-circle-o mx-3 icon"></i>Cheque
                    Service
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/request"
                  >
                    <i className="fa fa-question-circle-o mx-3 icon"></i>Request
                    Send
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/credit-management"
                  >
                    <i className="fa fa-credit-card mx-3 icon"></i>Credit
                    Management
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/job"
                  >
                    <i class="fa fa-bell-o mx-3 icon"></i>
                    Job
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/notification"
                  >
                    <i class="fa fa-bell-o mx-3 icon" aria-hidden="true"></i>
                    Notification
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/slider"
                  >
                    <i className="fa fa-sliders mx-3 icon"></i>Slider
                  </NavLink>{" "}
                </li>

                <li
                  className="list-name"
                  data-bs-toggle="collapse"
                  href="#setting"
                >
                  <Link to="#setting">
                    <i className="fa fa-cog mx-3 icon"></i>Setting{" "}
                    <i
                      class="fas fa-angle-down "
                      style={{ float: "right", marginTop: "3px" }}
                    ></i>
                    <div className="collapse submenu-dropdown" id="setting">
                      <Link to="" onClick={ChangePW} className="link">
                        <div className="sublist-name">Change Password</div>
                      </Link>
                    </div>
                  </Link>
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={() =>
                      openInNewTab(
                        "https://docs.google.com/document/d/17MMJ7YbpxNNiMoIfuK2JK-6BZ1kH8yIu71f8B4JSe8Y/edit?usp=sharing"
                      )
                    }
                    to=""
                  >
                    <i className="fa fa-question-circle-o mx-3 icon"></i>User
                    Manual<sup className="sup-col">*</sup>
                  </NavLink>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </>
      ) : (
        <>
          <li className="nav-item">
            <NavLink
              activeClassName={classes.active}
              className="nav-link"
              to="/login"
            >
              Login
            </NavLink>
          </li>
        </>
      )}
    </>
  );
}
