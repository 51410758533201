import React, { useContext, useState, useRef } from "react";
import "../../css/Commission.css";
import CreditManagementContext from "../contextfolder/CreditManagementContext/CreditManagementContext";
import CreditManagementForm from "./CreditManagementForm";
import CreditManagementTable from "./CreditManagementTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";

function CreditManagement() {
  const context = useContext(CreditManagementContext);
  const {
    modal,
    toggleModal,
    setCreditFormValue,
    setCreditFormError,
    creditvalue,
    setImage,
    status,
    setStatus,
  } = context;

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setCreditFormValue(creditvalue);
    setCreditFormError({});
    setImage("");
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setCreditFormValue(creditvalue);
  };

  const [searchTerm, setSearchTerm] = useState("");

  // For print purpose

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "report-data",
    // onAfterPrint: () => alert("print success")
  });

  const handleStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setStatus(value);
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="header--section">
          <div className="fontHeader">Credit Management</div>

          <div className="header--button">
            <button className="btn btn-cmpy btn-sm" onClick={addnew}>
              {" "}
              Add Credit +
            </button>
          </div>
        </div>
        <hr />
        <section className="content-section main-content">
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-sm-2">
                  <label>Status</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="status"
                    className="form-control form-control-sm mb-1"
                    value={status}
                    onChange={handleStatus}
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style={{ fontSize: "11px" }}
                    >
                      Select Status
                    </option>
                    <option value="-1">All</option>
                    <option value="1">Approve</option>
                    <option value="2">Decline</option>
                    <option value="0">Pending</option>
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-2 print-pdf-cheque">
                  <i
                    className="fa fa-print account-bar"
                    aria-hidden="true"
                    onClick={handlePrint}
                    style={{ fontSize: "18px" }}
                  ></i>

                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="fa fa-file-excel-o"
                    table="table-to-xls"
                    filename="report"
                    sheet="tablexls"
                    buttonText=""
                  >
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  </ReactHTMLTableToExcel>
                </div>
                <div
                  className="col-lg-2 offset-lg-6 col-md-3 col-sm-2 search-icon-position-cheque"
                  style={{ padding: "5px" }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    className="form-control form-control-sm mb-1"
                    style={{ fontSize: "12px" }}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <i
                    style={{ color: "#999", top: "14px" }}
                    className="fas fa-search searchIconPosition"
                  ></i>
                </div>
              </div>
            </div>
            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <CreditManagementTable
                      searchTerm={searchTerm}
                      componentRef={componentRef}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>
      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="credit-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Credit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <CreditManagementForm />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreditManagement;
