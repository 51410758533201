import React, { useContext, useState } from 'react'
import '../../css/Commission.css';
import SliderContext from '../contextfolder/SliderContext/SliderContext';
import SliderForm from './SliderForm';
import SliderTable from './SliderTable';


function Slider() {

    const context = useContext(SliderContext)
    const { modal, toggleModal, slidervalue, setSliderFormError, setSliderFormValue } = context;

    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setSliderFormValue(slidervalue)
        setSliderFormError({})
    }


    const [searchTerm, setSearchTerm] = useState("");

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
            <div className="header--section">

<div className="fontHeader">Slider</div>

<div className='header--button'>
    <button className="btn btn-cmpy btn-sm" onClick={toggleModal}> Add Slider +</button>
</div>

</div>
                <hr />
                <section className="content-section main-content">
                    <div className="content">
                        <div className=" col-lg-12 col-sm-12">

                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 p-2 Search">
                                    <div className="dashboard-search-field">
                                        <input type="text" placeholder="Search" value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                            }} style={{ border: "none" }} />
                                        <i style={{ paddingTop: "7px", paddingRight: "5px", color: "#999" }} className="fas fa-search searchIconPosition"></i>
                                    </div>
                                </div>

                                {/* <div className="col-lg-6 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                                    <button className="btn btn-cmpy btn-sm" onClick={toggleModal}> Add Slider +</button>
                                </div> */}

                            </div>
                        </div>
                        {<div className="row">
                            <div className="">
                                <div className="col-lg-12 p-1">

                                    <SliderTable searchTerm={searchTerm} />

                                </div>
                            </div>

                        </div>}


                    </div>
                </section>
            </div>
            {modal && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="slider-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Add Slider</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div>
                            <SliderForm />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Slider