import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AuthContext from "./component/auth-context";
import ChequeService from "./component/ChequeServicePage/ChequeService";
import Commission from "./component/Commissionpage/Commission";
import ChequeServiceState from "./component/contextfolder/ChequeServiceContext/ChequeServiceState";
import CommissionState from "./component/contextfolder/CommissionContext/CommissionState";
import CooperativeState from "./component/contextfolder/Cooperativecontext/CooperativeState";
import CreditManagementState from "./component/contextfolder/CreditManagementContext/CreditManagementState";
import InternalFundState from "./component/contextfolder/InternalFundContext/InternalFundState";
import LoadWalletState from "./component/contextfolder/LoadWalletContext/LoadWalletState";
import NotificationState from "./component/contextfolder/NotificationContext/NotificationState";
import RemittanceState from "./component/contextfolder/RemittanceContext/RemittanceState";
import ReportState from "./component/contextfolder/ReportContext/ReportState";
import RequestState from "./component/contextfolder/RequestSendContext/RequestState";
import SliderState from "./component/contextfolder/SliderContext/SliderState";
import UserState from "./component/contextfolder/UserContext/UserState";
import Cooperative from "./component/Cooperativepage/Cooperative";
import CreditManagement from "./component/CreditManagementPage/CreditManagement";
import Dashboard from "./component/DashboardPage/Dashboard";
import InternalFund from "./component/InternalFundPage/InternalFund";
import LandingPage from "./component/LandingPage/LandingPage";

import Layout from "./component/Layout";
import Login from "./component/Login";
import Notification from "./component/NotificationPage/Notification";
import MainOperator from "./component/OperatorPage/MainOperator";
import Operator from "./component/OperatorPage/Operator";
import PrivacyPolicy from "./component/PrivacyPolicy";
import MainReport from "./component/ReportPage/MainReport";
import Report from "./component/ReportPage/Report";
import Request from "./component/RequestPage/Request";
import SidebarState from "./component/sidebarcontext/SidebarState";
import Slider from "./component/Sliderpage/Slider";
import User from "./component/UserPage/User";
import QRTransferState from "./component/contextfolder/QRTransferContext/QRTransferState";
import BankTransferState from "./component/contextfolder/BankTransferContext/BankTransferState";
import EliteJobState from "./component/contextfolder/EliteJobContext/EliteJobState";
import CandidateState from "./component/contextfolder/CandidateContext/CandidateState";
import EliteJob from "./component/EliteJob/EliteJob";

function App() {
  const auth = localStorage.getItem("userInfo");
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/privacy-policy/:name" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="*" element={<Login />} /> */}
        {!auth && <Route path="/login" element={<Login />} />}
      </Routes>

      <div className="App">
        {authCtx.isLoggedIn && (
          <SidebarState>
            <EliteJobState>
              <CandidateState>
                <UserState>
                  <CreditManagementState>
                    <NotificationState>
                      <SliderState>
                        <RequestState>
                          <ChequeServiceState>
                            <BankTransferState>
                              <QRTransferState>
                                <LoadWalletState>
                                  <RemittanceState>
                                    <InternalFundState>
                                      <ReportState>
                                        <CooperativeState>
                                          <CommissionState>
                                            <Layout>
                                              <Routes>
                                                <Route
                                                  path="/dashboard"
                                                  element={<Dashboard />}
                                                />
                                                <Route
                                                  path="/commission"
                                                  element={<Commission />}
                                                />
                                                <Route
                                                  path="/cooperative"
                                                  element={<Cooperative />}
                                                />
                                                <Route
                                                  path="/slider"
                                                  element={<Slider />}
                                                />
                                                <Route
                                                  path="/report"
                                                  element={<MainReport />}
                                                />
                                                <Route
                                                  path="/operator"
                                                  element={<MainOperator />}
                                                />
                                                <Route
                                                  path="/summary"
                                                  element={<Operator />}
                                                />
                                                <Route
                                                  path="/internal"
                                                  element={<InternalFund />}
                                                />
                                                <Route
                                                  path="/cheque-service"
                                                  element={<ChequeService />}
                                                />
                                                <Route
                                                  path="/request"
                                                  element={<Request />}
                                                />
                                                <Route
                                                  path="/credit-management"
                                                  element={<CreditManagement />}
                                                />
                                                <Route
                                                  path="/notification"
                                                  element={<Notification />}
                                                />
                                                <Route
                                                  path="/user"
                                                  element={<User />}
                                                />
                                                <Route
                                                  path="/job"
                                                  element={<EliteJob />}
                                                />
                                                <Route
                                                  path="/privacy-policy/:name"
                                                  element={<PrivacyPolicy />}
                                                />
                                              </Routes>
                                            </Layout>
                                          </CommissionState>
                                        </CooperativeState>
                                      </ReportState>
                                    </InternalFundState>
                                  </RemittanceState>
                                </LoadWalletState>
                              </QRTransferState>
                            </BankTransferState>
                          </ChequeServiceState>
                        </RequestState>
                      </SliderState>
                    </NotificationState>
                  </CreditManagementState>
                </UserState>
              </CandidateState>
            </EliteJobState>
          </SidebarState>
        )}
      </div>
    </>
  );
}

export default App;
