import React, { useContext, useEffect, useState } from 'react'
import '../../css/Dashboard.css'
import logo from '../../images/logo.png'
import SidebarContext from '../sidebarcontext/SidebarContext';
import CryptoJS from "crypto-js";

const Dashboard = () => {

  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { url, setMsg } = useContext(SidebarContext)
  const [loading, setLoading] = useState(true);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));


  const [dashboardList, setDashboardList] = useState([])

  //API to hit Dashboard list

  useEffect(() => {
    getDashboardList();
  }, [])


  const getDashboardList = () => {
    const listForm = {
      CoOperativeCode: userinfo.coOperativeCode,
      BranchID: "",
      IsEncryptReq: "N",
      TimeStamp: "2022-05-02T01:35:44.345",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(listForm),
      key, //dataForm
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const dts = {
      EncrytedText: encrypted.toString(),
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Signature: apisignature,
      },
      body: JSON.stringify(dts)
    };
    fetch(`${url}/CoOperative/DashBoarData`, requestOptions)
      .then((result) => {
        result.json().then((resp) => {
          if (resp.statuS_CODE === "0") {
            setDashboardList(resp)
            setLoading(false)
          }
          else {
            setDashboardList({})
            setLoading(false)

          }
        })
      })
  };














  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div>
          <div className="mainHeader">
            <div className="fontHeader">Dashboard</div>

          </div>
          <hr />
        </div>
        {/* <section className="content-section main-content"> */}
        <section style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div className="dashboard--container" style={{ borderRadius: "5px" }}>
            <div className="row">

              {/* <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">Student</h6>
                  <div className="middle--about">
                    <div>
                      <div><img className="dashboard--logo" src={logo} alt="" />  </div>
                    </div>
                    <div className="dashboard--info"><p>ES Pay </p> <p className="dashboard--address"> Address, Address<br />
                      01-123456789
                    </p>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">No. of Customer </h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.numOfCustomer === null ? "0" : dashboardList.numOfCustomer}</p>
                      {/* <p>{(dashboardList.numOfCustomer).split('"')[1]}</p> */}
                    </div>
                    <div><i className="fa fa-users dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">No. of Transaction</h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.numOfTxn === null ? "0" : dashboardList.numOfTxn}</p>
                    </div>
                    <div><i className="fa fa-money dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>
              </div>


              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">Credit Limit Remaining</h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.coOperativeCreditLimit === null ? "0.00" : dashboardList.coOperativeCreditLimit}</p>
                    </div>
                    <div><i className="fa fa-credit-card-alt dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">Total Commission Earned</h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.totalCommEarned === null ? "0.00" : dashboardList.totalCommEarned}</p>
                    </div>
                    <div><i className="fa fa-money dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">Total Number of SMS Sent </h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.totalNumOFSmsSent === null ? "0" : dashboardList.totalNumOFSmsSent}</p>
                    </div>
                    <div><i className="fa fa-comments dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">No. of Fund Transfer</h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.numFundTransfer === null ? "0" : dashboardList.numFundTransfer}</p>
                    </div>
                    <div><i className="fa fa-credit-card dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Dashboard