import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import NotificationContext from "../contextfolder/NotificationContext/NotificationContext";
import DeletePop from "./DeletePop";


const NotificationTable = ({ searchTerm }) => {

    const context = useContext(NotificationContext)
    const { notificationList, delPopup, handleDeleteTrue, handleDeleteFalse, handleDelete, chooseMember } = context;


    if (notificationList.length > 0) {
        return (
            <>
                <div className="table-responsive br-3">

                    <table className="table table-bordereds text-nowrap mb-0">
                        <thead>
                            <tr className="tableHead">
                                <td className="tc">S.N.</td>
                                {chooseMember === "-1" ? <></> :
                                    <td className="tl" style={{ width: "100px" }}>UserName</td>
                                }
                                <td className="tc">Date</td>
                                <td className="tl">Title</td>
                                <td className="tl" style={{ width: "300px" }}>Description</td>
                                <td classname="tl">Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {notificationList.filter((val) => {
                                if (searchTerm === "") {
                                return val;
                                } else if (
                                    val.userName.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    ) || val.notificationHeader.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    )
                                ) {
                                    return val;
                                }
                            }).map((item, i) => (
                                <tr key={i}>
                                    <td className="tc">{i + 1}</td>
                                    {chooseMember === "-1" ? <></> :
                                        <td className="tl" style={{ width: "100px" }}>{item.userName}</td>
                                    }
                                    <td className="tc">{item.englishDate}</td>
                                    <td className="tl"> {item.notificationHeader}</td>
                                    <td className="tl" style={{ width: "300px" }}>{item.noticationBody}</td>
                                    <td className="tc">
                                        <span
                                            className="rejectspan badge"
                                            onClick={() => { handleDelete(item.id) }}
                                        >
                                            Remove
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {delPopup.show && (
                    <DeletePop
                        handleDeleteTrue={handleDeleteTrue}
                        handleDeleteFalse={handleDeleteFalse}
                    />
                )}
            </>
        );
    } else {
        return (
            <div className="table-responsive br-3">

                    <table className="table table-bordereds text-nowrap mb-0">
                        <thead>
                            <tr className="tableHead">
                                <td className="tc">S.N.</td>
                                <td className="tl">UserName</td>
                                <td className="tc">Date</td>
                                <td className="tl">Title</td>
                                <td className="tl">Description</td>
                                <td classname="tc">Action</td>
                            </tr>
                        </thead>
                    </table>
                    <div className="text-center mt-4">
                        <p>No data found</p>
                    </div>
                </div>
        );
    }
};

export default NotificationTable;