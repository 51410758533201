import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import SliderContext from "../contextfolder/SliderContext/SliderContext";
import { showImgPreview } from "../hooks/ImagePreview";
import DeletePop from "./DeletePop";

const SliderTable = ({ searchTerm }) => {

    const { sliderList, handleDeleteTrue, handleDeleteFalse, delPopup, handleDelete } = useContext(SliderContext)


    const [imgPrv, setImgPrv] = useState(false);
    const [imagePre, setImagePre] = useState("");


    if (sliderList.length > 0) {
        return (
            <>

                <div
                    className="table-responsive br-3"
                >
                    <table className="table table-bordereds text-nowrap mb-0">
                        <thead>
                            <tr className="tableHead">
                                <td className="tl" style={{ paddingLeft: "20px" }}>S.N.</td>
                                <td className="tc">Image</td>

                                <td className="tc" style={{ width: "220px" }}>
                                    {" "}
                                    Action
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {sliderList.filter((val) => {
                                if (searchTerm === "") {
                                    return val;
                                } else if (
                                    val.imgPath.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    )
                                ) {
                                    return val;
                                }
                            }).map((item, i) => (
                                <tr key={i}>

                                    <td className="tl" style={{ paddingLeft: "20px" }}>{i + 1}</td>
                                    <td className="contentLogo tc" style={{ display: "flex", justifyContent: "center" }}><div className="mgmtImg tc"><img src={item.imgPath} onClick={() => { setImagePre(item.imgPath); setImgPrv(true) }} alt="slider" /> </div></td>

                                    <td className="tc">
                                        <span
                                            className="rejectspan badge" onClick={() => { handleDelete(item.sliderID) }}

                                        >
                                            Remove
                                        </span>



                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                {delPopup.show && (
                    <DeletePop
                        handleDeleteTrue={handleDeleteTrue}
                        handleDeleteFalse={handleDeleteFalse}
                    />
                )}
                {imgPrv &&
                    showImgPreview({
                        img: imagePre,
                        setTrigger: setImgPrv,
                    })}

            </>
        );
    }
    else {
        return (
            <div
                className="table-responsive br-3"
            >
                <table className="table table-bordereds text-nowrap mb-0">
                    <thead>
                        <tr className="tableHead">
                            <td className="tc">S.N.</td>
                            <td className="tc">Image</td>

                            <td className="tl" style={{ width: "220px" }}>
                                {" "}
                                Action
                            </td>
                        </tr>
                    </thead>
                </table>
                <div className="text-center mt-4">
                    <p>No Data Found</p>
                </div>
            </div>
        );
    }
};

export default SliderTable;
