import React, { useContext, useEffect, useState } from "react";
import "../../css/Commission.css";
import RequestContext from "../contextfolder/RequestSendContext/RequestContext";


const RequestTable = ({ searchTerm, componentRef }) => {


    const { requestList } = useContext(RequestContext)


    if (requestList.length > 0) {
        return (
            <>
                <div className="table-responsive br-3">

                    <table className="table table-bordereds text-nowrap mb-0" id="table-to-xls" ref={componentRef}>
                        <thead>
                            <tr className="tableHead">
                                <td className="tc">S.N.</td>
                                <td className="tc">Date</td>
                                <td className="tl">Username</td>
                                <td className="tl">Request Detail</td>
                            </tr>
                        </thead>
                        <tbody>
                            {requestList.filter((val) => {
                                if (searchTerm === "") {
                                    return val;
                                } else if (
                                    val.requestDetails.toLowerCase().includes(
                                        searchTerm.toLowerCase()
                                    )
                                ) {
                                    return val;
                                }
                            }).map((item, i) => (
                                <tr key={i}>
                                    <td className="tc">{i + 1}</td>
                                    <td className="tc">{item.createdDate}</td>
                                    <td className="tl">{item.userID}</td>
                                    <td className="tl">{item.requestDetails}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>




            </>
        );
    }
    else {
        return (
            <div className="table-responsive br-3">

                <table className="table table-bordereds text-nowrap mb-0">
                    <thead>
                        <tr className="tableHead">
                            <td className="tl">S.N.</td>
                            <td className="tc">Date</td>
                            <td className="tl">Username</td>
                            <td className="tc">Request Detail</td>
                        </tr>
                    </thead>
                </table>
                <div className="text-center mt-4">
                    <p>No Data Found</p>
                </div>
            </div>
        );
    }
};

export default RequestTable;
