import React, { useContext, useState, useEffect } from 'react'
import CreditManagementContext from '../contextfolder/CreditManagementContext/CreditManagementContext';
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";

export default function CreditManagementForm(props) {

    const context = useContext(CreditManagementContext)

    const { toggleModal, creditFormValue, setCreditFormValue, creditFormError, setCreditFormError, isSubmit, setIsSubmit, loading, setLoading, creditvalue, image, setImage, isUploaded, setIsUploaded, typeFile, setTypeFile, fetchdata } = context;



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setCreditFormValue({ ...creditFormValue, [name]: value });
    };

    const formSubmit = (e) => {

        e.preventDefault();
        setCreditFormError(validate(creditFormValue));
        setIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(creditFormError).length === 0 && isSubmit) {
            fetchdata(creditFormValue)
            setCreditFormValue(creditvalue)
            setIsSubmit(false);
            toggleModal();
            setImage("")
            setIsUploaded(false)
        }

    }, [creditFormError]);



    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setCreditFormValue(creditvalue)
        setCreditFormError({})
        setImage("")
        setIsUploaded(false)
    }

    const validate = (values) => {
        const errors = {};
        if (!values.creditAmount) {
            errors.creditAmount = "Required";
        }
        if (!values.remarks) {
            errors.remarks = "Required";
        }

        return errors;
    };

    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            setTypeFile(e.target.files[0].type);
            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-row">


                        <div className="form-group col-md-12  ">
                            <label className="form-label" htmlFor="creditAmount">Credit Amount<sup className="sup-col">*</sup></label>
                            <input type="text" className="form-control form-control-sm mb-1" onChange={handleChange} value={creditFormValue.creditAmount} id="creditAmount" name="creditAmount" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{creditFormError.creditAmount}</p>
                        </div>




                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12  ">
                            <label className="form-label" htmlFor="remarks">Remarks<sup className="sup-col">*</sup></label>
                            <input type="textarea" className="form-control form-control-sm mb-1" onChange={handleChange} value={creditFormValue.remarks} id="remarks" name="remarks" />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{creditFormError.remarks}</p>

                        </div>


                    </div>

                    <div className="form-group  ">
                        <div className="form-label" htmlFor="text">
                            Upload Image
                        </div>
                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!isUploaded ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </form>

            </div>
            <div className=" FormFooter">
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div>

        </>

    )
}
