import React, { useContext, useState } from "react";
import "../../css/Commission.css";
import "../../css/Notification.css";
import "../ReportPage/MainReport.css";
import Operator from "./Operator";

function MainOperator() {
  const [activeTab, setActiveTab] = useState({
    tab1: true,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
    });
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSections">
        <div>
          <div className="mainHeader">
            <div className="fontHeader">Summary</div>
          </div>
          <hr />
        </div>
        <section className="content-section main-content">
          <div className="content">
            <div className="insidePopup">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  <i className="fa fa-file-text-o icon"></i>Operator Summary{" "}
                </li>
              </ul>
            </div>
            <div>{activeTab.tab1 && <Operator />}</div>
          </div>
        </section>
      </div>
    </>
  );
}

export default MainOperator;
