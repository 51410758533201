import React, { useContext, useState } from "react";
import "../../css/Commission.css";
import "../../css/Notification.css";
import "./MainReport.css";
import InternalFund from "../InternalFundPage/InternalFund";
import Operator from "../OperatorPage/Operator";
import Report from "./Report";
import Remittance from "../Remittance/Remittance";
import LoadWallet from "../Load Wallet/LoadWallet";
import QRTransfer from "../QR Transfer/QRTransfer";
import BankTransfer from "../BankTransfer/BankTransfer";

function MainReport() {
  const [activeTab, setActiveTab] = useState({
    tab1: true,
    // tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false,
    tab7: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      // tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
      tab7: false,
    });
  };
  // const handleTab2 = () => {
  //   setActiveTab({
  //     tab1: false,
  //     tab2: true,
  //     tab3: false,
  //     tab4: false,
  //     tab5: false,
  //   });
  // };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      // tab2: false,
      tab3: true,
      tab4: false,
      tab5: false,
      tab6: false,
      tab7: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      // tab2: false,
      tab3: false,
      tab4: true,
      tab5: false,
      tab6: false,
      tab7: false,
    });
  };
  const handleTab5 = () => {
    setActiveTab({
      tab1: false,
      // tab2: false,
      tab3: false,
      tab4: false,
      tab5: true,
      tab6: false,
      tab7: false,
    });
  };
  const handleTab6 = () => {
    setActiveTab({
      tab1: false,
      // tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: true,
      tab7: false,
    });
  };
  const handleTab7 = () => {
    setActiveTab({
      tab1: false,
      // tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
      tab7: true,
    });
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSections">
        <div>
          <div className="mainHeader">
            <div className="fontHeader">Report</div>
          </div>
          <hr />
        </div>
        <section className="content-section main-content">
          <div className="content">
            <div className="insidePopup">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  <i className="fa fa-file-text-o icon"></i>Utility Report{" "}
                </li>
                {/* <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  <i className="fa fa-file-text-o icon"></i>Operator Summary{" "}
                </li> */}
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  <i className="fa fa-file-text-o icon"></i>Internal Fund
                  Transfer{" "}
                </li>
                <li
                  className={activeTab.tab4 === true ? "active" : ""}
                  onClick={handleTab4}
                >
                  <i className="fa fa-file-text-o icon"></i>Remittance{" "}
                </li>
                <li
                  className={activeTab.tab5 === true ? "active" : ""}
                  onClick={handleTab5}
                >
                  <i className="fa fa-file-text-o icon"></i>Load Wallet{" "}
                </li>
                <li
                  className={activeTab.tab6 === true ? "active" : ""}
                  onClick={handleTab6}
                >
                  <i className="fa fa-file-text-o icon"></i>QR Transfer{" "}
                </li>
                <li
                  className={activeTab.tab7 === true ? "active" : ""}
                  onClick={handleTab7}
                >
                  <i className="fa fa-file-text-o icon"></i>Bank Transfer{" "}
                </li>
              </ul>
            </div>
            <div>
              {activeTab.tab1 && <Report />}
              {/* {activeTab.tab2 && <Operator />} */}
              {activeTab.tab3 && <InternalFund />}
              {activeTab.tab4 && <Remittance />}
              {activeTab.tab5 && <LoadWallet />}
              {activeTab.tab6 && <QRTransfer />}
              {activeTab.tab7 && <BankTransfer />}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default MainReport;
