import React, { useContext, useState } from 'react'
import SidebarContext from '../../sidebarcontext/SidebarContext';
import CooperativeContext from './CooperativeContext';
import CryptoJS from "crypto-js";


function CooperativeState(props) {
    const [cooperativeFormError, setCooperativeFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [editIsSubmit, setEditIsSubmit] = useState(false);

    const [image, setImage] = useState("-");
    const [isUploaded, setIsUploaded] = useState(false);
    const [typeFile, setTypeFile] = useState("");

    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { url, setMsg } = useContext(SidebarContext)

    const [allowApp, setAllowApp] = useState(false);

    // For popup
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    const [editModal, setEditModal] = useState(false);

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };
    //End

    const cooperativevalue = {
        CoopID: '',
        CoopName: '',
        nickName: '',
        address: '',
        color: '',
        serverName: '',
        dbName: '',
        contactPerson: '',
        contactNumber: '',
        url: '',
        creditLimit: '',
        noOfUser: '',
        expire: '',
        logo: '',
        dbUsername: '',
        dbPassword: '',
        serverKey: '',
        senderId: '',
    };



    const [cooperativeFormValue, setCooperativeFormValue] = useState(cooperativevalue);


    const fetchdata = async () => {

        const dataForm = {
            Flag: "u",
            CooperativeCode: cooperativeFormValue.CoopID,
            ServerName: cooperativeFormValue.serverName,
            DbName: cooperativeFormValue.dbName,
            DbUserName: cooperativeFormValue.dbUsername,
            DbPwd: cooperativeFormValue.DbPwd,
            CooperativeName: cooperativeFormValue.CoopName,
            NickName: cooperativeFormValue.nickName,
            Logo: cooperativeFormValue.logo,
            Contact: cooperativeFormValue.contactNumber,
            Address: cooperativeFormValue.address,
            ColorCode: cooperativeFormValue.color,
            IsPaid: "i",
            LicenseExpiry: cooperativeFormValue.expire,
            CBSUrl: cooperativeFormValue.url,
            ScopeType: "i",
            CreditLimit: cooperativeFormValue.creditLimit,
            NumOfUser: cooperativeFormValue.noOfUser,
            ServerKey: cooperativeFormValue.serverKey,
            SenderID: cooperativeFormValue.senderId,
            CreatedDate: "i",
            StaffID: "1",

        }
        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const dts = {
            EncrytedText: encrypted.toString(),
        };
        const response = await fetch("http://testing.esnep.com/MblPayPanel/api/sadmin/cooperative", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const pay = await response.json();


        if (pay.statuS_CODE === "0") {
            // getCommissionList();
            setMsg({
                msg: pay.message,
                type: "alert alert-success",
            });
        } else if (pay.statuS_CODE === "1") {
            setMsg({
                msg: pay.message,
                type: "alert alert-danger",
            });
        }

    };

    return (
        <CooperativeContext.Provider value={{ modal, setModal, toggleModal, cooperativeFormError, setCooperativeFormError, isSubmit, setIsSubmit, cooperativevalue, cooperativeFormValue, setCooperativeFormValue, image, setImage, isUploaded, setIsUploaded, typeFile, setTypeFile, editIsSubmit, setEditIsSubmit, editModal, setEditModal, toggleEditModal, allowApp, setAllowApp, fetchdata }}>{props.children}</CooperativeContext.Provider>
    )
}

export default CooperativeState;