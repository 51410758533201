import React, { useState, useEffect, useContext } from 'react'
import CommissionContext from './CommissionContext';
import CryptoJS from "crypto-js";
import SidebarContext from '../../sidebarcontext/SidebarContext';




function CommissionState(props) {
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { url, setMsg } = useContext(SidebarContext)
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    const [loading, setLoading] = useState(false);

    const [inputFields, setInputFields] = useState([])

    //API to hit category commission list 

    //API to hit Cooperative list
    const [commissionList, setCommissionList] = useState([]);

    useEffect(() => {
        comList();
    }, []);

    const comList = async () => {
        const dataForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            IsCoOperative: "N",
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345"
        }

        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const dts = {
            EncrytedText: encrypted.toString(),
        };

        const response = await fetch(`${url}/Category/GetCommissionCategoryList`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const pay = await response.json();


        if (pay.statuS_CODE === "0") {
            const postResult = pay.lstCategory ? pay.lstCategory : "";
            setCommissionList(postResult);
            setLoading(false);

        } else {
            setLoading(false);
            setCommissionList([]);

        }

    };

    const [id, setId] = useState("")
    const [isSubmit, setIsSubmit] = useState(false)


    //API to send customer data


    const sendData = async () => {
        const dataForm = {
            CoOperativeCode: userinfo.coOperativeCode,
            UserName: userinfo.UserName,
            IsEncryptReq: "N",
            TimeStamp: "2022-05-02T01:35:44.345",
            flag: "U",
            categoryId: id,
            IsCoOperative: "Y",
            CommissionDetails: inputFields
        }

        var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
        var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

        var encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(dataForm),
            key, //dataForm
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const dts = {
            EncrytedText: encrypted.toString(),
        };
        const response = await fetch(`${url}/Category/UpdateCategoryDetails`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Signature: apisignature,
            },
            body: JSON.stringify(dts), //dts
        });
        const pay = await response.json();


        if (pay.statuS_CODE === "0") {
            comList();
            setMsg({
                msg: pay.message,
                type: "alert alert-success",
            });
        } else {
            setMsg({
                msg: pay.message,
                type: "alert alert-danger",
            });
        }

    };





    // To Edit Commission Value

    // const [comId, setComId] = useState("")

    // const handleEdit = (datas) => {
    //     editToggleModal();
    //     setComId(datas.categoryId)
    //     setCommissionFormValue({
    //         category: datas.categoryName,
    //         operator: datas.operatorName,
    //         comType: datas.categoryType,
    //         flatRate: datas.categoryType && datas.categoryType === "P" ? datas.percentageRate : datas.flatRate
    //     })

    // }

    // const editdata = async () => {

    //     const dataForm = {
    //         CoOperativeCode: userinfo.coOperativeCode,
    //         UserName: userinfo.UserName,
    //         categoryId: comId,
    //         categoryName: commissionFormValue.category,
    //         IsCoOperative: "Y",
    //         operatorName: commissionFormValue.operator,
    //         categoryType: commissionFormValue.comType,
    //         flatRate: commissionFormValue.comType === "F" ? commissionFormValue.flatRate : 0,
    //         percentageRate: commissionFormValue.comType === "P" ? commissionFormValue.flatRate : 0,
    //         IsEncryptReq: "N",
    //         TimeStamp: "2022-05-02T01:35:44.345"
    //     }
    //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    //     var encrypted = CryptoJS.AES.encrypt(
    //         JSON.stringify(dataForm),
    //         key, //dataForm
    //         {
    //             iv: iv,
    //             mode: CryptoJS.mode.CBC,
    //             padding: CryptoJS.pad.Pkcs7,
    //         }
    //     );

    //     const dts = {
    //         EncrytedText: encrypted.toString(),
    //     };
    //     const response = await fetch(`${url}/Category/UpdateDeleteCategory`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Signature: apisignature,
    //         },
    //         body: JSON.stringify(dts), //dts
    //     });
    //     const pay = await response.json();
    //     

    //     if (pay.statuS_CODE === "0") {
    //         // getCommissionList();
    //         setMsg({
    //             msg: pay.message,
    //             type: "alert alert-success",
    //         });
    //     } else if (pay.statuS_CODE === "1") {
    //         setMsg({
    //             msg: pay.message,
    //             type: "alert alert-danger",
    //         });
    //     }

    // };


    return (
        <CommissionContext.Provider value={{ commissionList, inputFields, setInputFields, setId, sendData, isSubmit, setIsSubmit }}>{props.children}</CommissionContext.Provider>
    )
}

export default CommissionState;

