import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import joblogo from "../../images/jobLogo.png";
import "./elitejob.css";
import EliteJobLoginPop from "./EliteJobLoginPop";
import Job from "./Job";
import { FetchJobdata } from "../hooks/getJobData";
import { toast } from "react-toastify";

const EliteJobLogin = ({
  // checkUser,
  // setCheckUser,
  checkCompany,
  setCheckCompany,
}) => {
  const { AcademicYear, todayDate } = useContext(SidebarContext);

  const { jobURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(false);

  const enableUser = () => {
    setLoading(true);
  };

  // const userSync = () => {
  //   const params = {
  //     ComID: userinfo.SchID,
  //     Flag: "i",
  //     UserName: userinfo.UserName,
  //     FirstName: userinfo.FullName.split(" ")[0],
  //     LastName: userinfo.FullName.split(" ")[1],
  //     Password: userinfo.UserName,
  //     Image: userinfo.UserImage,
  //     Type: "POST",
  //     FetchURL: `${jobURL}api/job/user-sync`,
  //   };

  //   FetchJobdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       setCheckUser(true);
  //       setLoading(false);
  //       toast(result.Message, {
  //         style: {
  //           color: "green",
  //           fontSize: "13px",
  //         },
  //       });
  //     } else {
  //       setCheckUser(false);
  //       setLoading(false);
  //       toast(result.Message, {
  //         style: {
  //           color: "red",
  //           fontSize: "13px",
  //         },
  //       });
  //     }
  //   });
  // };

  const comSync = () => {
    const params = {
      ComID: userinfo.coOperativeCode,
      Flag: "i",
      UserName: userinfo.UserName,
      Name: userinfo.FullName,
      Address: userinfo.PermAddress,
      District: "",
      IndustryID: "",
      Website: userinfo.SchWebsite,
      Bio: userinfo.Msg,
      Latitude: userinfo.LocLat,
      Longitude: userinfo.LocLong,
      Facebook: userinfo.SchFb,
      Instagram: "",
      Linkedin: "",
      Email: userinfo.Email,
      Logo: userinfo.SchLogo,
      Telephone: userinfo.SchTel,
      Type: "POST",
      FetchURL: `${jobURL}api/job/com-sync`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        setCheckCompany(true);
        setLoading(false);
        // toast(result.Message, {
        //   style: {
        //     color: "green",
        //     fontSize: "13px",
        //   },
        // });
      } else {
        setCheckCompany(false);
        setLoading(false);
        // toast(result.Message, {
        //   style: {
        //     color: "red",
        //     fontSize: "13px",
        //   },
        // });
      }
    });
  };

  useEffect(() => {
    if (loading) {
      // if (checkCompany) {
      // userSync();
      // } else {
      comSync();
      // userSync();
      // }
    }
  }, [loading]);

  // const [closeChecked, setCloseChecked] = useState(false);
  // const [popup, setPopup] = useState(false);
  // const togglePopup = () => {
  //   setPopup(!popup);
  // };

  // const closePopup = (e) => {
  //   e.preventDefault();
  //   togglePopup();
  //   // setAbsentFormValue(absentValue);
  //   // setAbsentFormError({});
  //   // setIsSubmit(false);
  // };

  // const addnew = (e) => {
  //   e.preventDefault();
  //   togglePopup();
  //   // setIsSubmit(false);
  //   // setAbsentFormValue(absentValue);
  //   // setAbsentFormError({});
  //   setCloseChecked(false);
  // };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
    

        <div className="content-section main-content">
          <div className="elite-job-login">
            <div className="elitejoblogo">
              <img src={joblogo} alt="logo" />
            </div>
            <h2>Elite Jobs</h2>
            <p>Join the best. Be the best</p>

            {!checkCompany ? (
              //  || !checkUser
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={enableUser}
                >
                  {loading ? "Please wait..." : "Enable"}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Elite Job</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <EliteJobLoginPop
                closePopup={closePopup}
                closeChecked={closeChecked}
                setCloseChecked={setCloseChecked}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default EliteJobLogin;
