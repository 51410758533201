import React, { useContext, useEffect, useState } from 'react'
import '../../css/Commission.css';
import CommissionContext from '../contextfolder/CommissionContext/CommissionContext';
import CommissionTable from './CommissionTable';



function Commission() {

    const context = useContext(CommissionContext)
    const { inputFields, sendData, setIsSubmit, isSubmit, setInputFields } = context;

    const formNext = (e) => {
        e.preventDefault();

        setIsSubmit(true);

        sendData()

    };







    const [searchTerm, setSearchTerm] = useState("");

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
                <div>
                    <div className="mainHeader">
                        <div className="fontHeader">Commission</div>

                    </div>
                    <hr />
                </div>
                <section className="content-section main-content">
                    <div className="content">
                        <div className=" col-lg-12 col-sm-12">

                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 p-2 Search">
                                    <div className="dashboard-search-field">
                                        <input type="text" placeholder="Search" value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                            }} style={{ border: "none" }} />
                                        <i style={{ paddingTop: "7px", paddingRight: "5px", color: "#999" }} className="fas fa-search searchIconPosition"></i>
                                    </div>
                                </div>

                                <div className="col-lg-6 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                                    <button className="btn btn-cmpy btn-sm" onClick={formNext}> Update </button>
                                </div>

                            </div>
                        </div>
                        {<div className="row">
                            <div className="">
                                <div className="col-lg-12 p-1">

                                    <CommissionTable searchTerm={searchTerm} />

                                </div>
                            </div>

                        </div>}


                    </div>
                </section>
            </div>



        </>
    )
}

export default Commission




